.loading-text {
  display: inline-block;
}

.dots {
  display: inline-block;
  width: 20px;
  text-align: left;
}

.loading .dots span {
  animation: loadingDots 1.5s infinite;
  opacity: 0;
}

.loading .dots span:nth-child(2) {
  animation-delay: 0.5s;
}

.loading .dots span:nth-child(3) {
  animation-delay: 1s;
}

@keyframes loadingDots {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.loading .dots, .loading .dots span {
  padding: 0;
  margin: o;
}