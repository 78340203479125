
 	
/*--------------------------------------------------
	Hero Section
---------------------------------------------------*/			
				
#hero {		
  width:100%;
  height:auto;
  position:relative;
  z-index:20;
  overflow:hidden;
}

#hero.has-image {
  z-index:2;
  
  overflow:hidden;
}

#hero-styles {
  position: relative;
  width: 100%;
  height:auto;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  margin: 0 auto;
  z-index:2;		
}

#hero.has-image #hero-styles {
  position: relative;
  height:auto;
}

#hero-caption {
  display: flex;
  align-items: center;
  width: 100%;
  height:auto;
  padding-top:80px;
  padding-bottom:120px;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;	
  text-align:left;
  pointer-events:initial;	
}

#hero-caption.text-align-center {
  justify-content: center;
}

#hero-caption.height-title {
  height: 100vh;
  padding-top:80px;
  padding-bottom:80px; 
}

#hero #hero-caption.no-padding-bottom {
  padding-bottom:0;
}

#hero.has-image #hero-caption {
  align-items: center;
  justify-content: center;
  height:100vh;
  padding-top:80px;
  padding-bottom:80px;
}

#hero.has-image #hero-caption.align-start {
  align-items: flex-end;
  justify-content: flex-start;
}

#hero #hero-caption .inner {
  display: block;
  width: auto;
  height: auto;
}

#hero-caption.text-align-center .inner {
  text-align:center;
}

#hero.has-image #hero-caption .inner {
  -webkit-transform: scale(1);
  transform: scale(1);
  transform-origin: bottom left;	
}

#hero.has-image #hero-caption.align-start .inner {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);	
  }

#hero-image-wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  margin: 0 auto;
  left: 0;
  top:0;
  right: 0;
}

#hero-background-layer {
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  z-index:0;
  overflow:hidden;
}

#hero-bg-image {
  background-size:cover;
  position:absolute;
  background-position:center center;
  width:100%;
  height:100%;
  z-index:0;
  opacity:0;
  overflow:visible;
   image-rendering: pixelated;
}

.load-project-page #hero-bg-image, .load-next-project #hero-bg-image, .load-project-thumb-with-title #hero-bg-image {
  opacity:1!important;
  -webkit-transform: scale(1.02);
  transform: scale(1.02);	
}

.hero-title {
  font-size: calc(1rem + 24.15vw);
  line-height: calc(1rem + 22vw);
  font-weight: 500;
  position: relative;
  display:block;
  width:auto;
  overflow:hidden;
  padding: 0;
  margin-bottom:0;
  margin-left:-0.6vw;
  color:#000;		
}

.text-align-center .hero-title {
  margin-left:0;
}

.height-title .hero-title {
  position:relative;
  overflow:visible;
  white-space: nowrap;
}

.height-title .hero-title div {
  display:inline-block;
}

.hero-title span {
  opacity:0;
  display: block;
  margin-top:0;
  min-width:3vw;
  transform: translateY(160px);
  -webkit-transform: translateY(160px);
}

.height-title .hero-title span {
  box-sizing:border-box;
  display:inline-block;
  transform-origin: bottom center;
}

.light-content .hero-title {
  color:#fff;		
}

.change-header .hero-title {
  color:#000;		
}

.hero-subtitle {
  font-size: 18px;
  font-weight:400;
  line-height:24px;
  margin-top:50px;
  width: 100%;
  z-index: 10;		
  position:relative;
  display:block;
  overflow:hidden;
  margin-left: 0px;
  color:#000;
  opacity:0.4;
}

.subtitle-padding-left .hero-subtitle {
  margin-left:0;
  margin-right:0;
  box-sizing:border-box;
  padding-left:50%;
  text-align:left;
}

.hero-subtitle span {
  position:relative;
  display:block;
  opacity:0;
  transform: translateY(60px);
  -webkit-transform: translateY(60px);
}

.inline-title .hero-subtitle span {
  display: inline-block;
}

.light-content .hero-subtitle {
  color: #fff;
}

.change-header .hero-subtitle {
  color: #000!important;
}

.hero-arrow {
  width: 36px;
  height: 50px;
  position: absolute;
  bottom: 50px;
  left: 80px;
  right: auto;
  margin: 0;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
}

.text-align-center .hero-arrow {		
  left: 0;
  right: 0;
  margin: auto;
}

.hero-arrow span {
  position:relative;
  height:100%;
  width:100%;
  opacity: 0;
  display: flex;
  box-sizing:border-box;
  background: transparent;
  justify-content: center;
  align-items: center;
  transform: translateY(30px);
  -webkit-transform: translateY(30px);
  border-radius: 50px;
  border: 2px solid rgba(0,0,0,0.4);
  cursor:pointer;
  -webkit-transition: border 0.2s ease-in-out 0s;
  transition: border 0.2s ease-in-out 0s;
}
.hero-arrow:hover span {
  border: 2px solid rgba(0,0,0,1);
}

.light-content .hero-arrow span {
  border: 2px solid rgba(255,255,255,0.5);
}

.light-content .hero-arrow:hover span {
  border: 2px solid rgba(255,255,255,1);
}

.hero-arrow i.arrow-icon {
  border: solid #000;
  border-width: 0px 3px 3px 0px;
  box-sizing: border-box;
  display: block;
  position: relative;
  top: 0px;
  left: 0px;
  height: 20px;
  width: 20px;
  transform: rotate(45deg) scale(0.5);
}





#hero-footer {
  position: absolute;
  width: 100%;
  padding: 28px 80px;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  font-weight: 500;
  font-size: 14px;
  line-height:60px;
  color:#222;
}

#hero-footer.has-border::after {
  content:"";
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  height:1px;
  opacity:0;
  background-color:rgba(0,0,0,0.15);
  transform: scaleX(0);
  transform-origin: center;
  transition: all 0.3s ease-out 0s;
}

.light-content #hero-footer.has-border::after {
  background-color:rgba(255,255,255,0.15);
}

#hero-footer.has-border.visible::after {
  opacity:1;
  transform: scaleX(1);
}

.light-content #hero-footer {
  color:#fff;
}

#hero-footer:after {
  content: "";
  clear: both;
  display: table;
}

.hero-footer-left {
  float:left;
  opacity:0;
  cursor:pointer;
  transform: translateY(10px);
  -webkit-transform: translateY(10px);
}

.hero-footer-right {
  float:right;
  opacity:0;		
  transform: translateY(10px);
  -webkit-transform: translateY(10px);
}

.no-grid-option .hero-footer-right {
  float:none;
}

#hero-description {
  position:relative;
  display: table;
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 200px;
  margin: 0 auto;
  box-sizing: border-box;
  pointer-events: initial;
}

#hero-description .inner {
  padding-right:55%;
}

#hero-description p {
  color:#000;
}

.light-content #hero-description p {
  color: #fff;
}

#share {
  top:-3px;
  right: -10px;
  position: relative;
  font-size:14px;
}

#share::before {
  position: absolute;
  top: 0;
  left: -140px;
  content: attr(data-text);
  width: 120px;
  text-align:right;
}

#share .parallax-wrap {
  width: 50px;
  height: 60px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  float: left;
}

.jssocials-share-link i {
  opacity:0;
}

.jssocials-share-facebook a:before {
  content:"Fb";
  position:absolute;
}

.jssocials-share-linkedin a:before {
  content:"Li";
  position:absolute;
}

.jssocials-share-twitter a:before {
  content:"Tw";
  position:absolute;
}

.jssocials-share-pinterest a:before {
  content:"Pn";
  position:absolute;
}

.jssocials-share-stumbleupon a:before {
  content:"St";
  position:absolute;
}

.jssocials-share-whatsapp a:before {
  content:"Wa";
  position:absolute;
}

.jssocials-share-viber a:before {
  content:"Vb";
  position:absolute;
}

.jssocials-share-email a:before {
  content:"Em";
  position:absolute;
}

.jssocials-share-vkontakte a:before {
  content:"Vk";
  position:absolute;
}


/*--------------------------------------------------
Main Content
---------------------------------------------------*/	


#main {
  position:relative;
  opacity:0;
  user-select: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
}

.load-project-page #main, .load-next-project #main, .load-next-page #main {
  opacity:1;
}

#main-content, #main-content-bg {
  position:relative;
  opacity:1;
  z-index:10;		
  -webkit-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

#main-page-content {
  position: relative;
  box-sizing:border-box;
  /* opacity: 0; */
  height: 100vh;
  width:100%;
  margin:0 auto;
  margin-bottom: 0;
  overflow:hidden;
  max-width:none;
}

#main-page-content::after {
  clear: both;
  content: " ";
  display: table;
}

#main-page-content.project-page {
  margin-bottom: calc(200vh - 120px);
}

.hero-below-caption #main-page-content.project-page {
  margin-bottom: calc(200vh - 120px);
}

#main-content.portfolio-page {		
  overflow:hidden;		
}

#main-content.portfolio-page #main-page-content {
  overflow:visible;
  width:100%;
  max-width:none;
  padding:0;
}

/*--------------------------------------------------
Page Navigation
---------------------------------------------------*/	

#page-nav {
  height: auto;
  width: 100%;
  color: #000;
  position: relative;
  margin-bottom: 0;
  opacity:0;
  overflow:hidden;
}

.light-content #page-nav {
  color: #fff;
}

.page-nav-wrap {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
}

.page-nav-caption {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding-top: 180px;
  padding-bottom: 130px;
  margin: 0 auto;
  height: 100%;
  top:0;
  opacity:0;
  position: relative;
  box-sizing: border-box;
}

.page-nav-caption.height-title {
  height: calc(100vh - 120px);
  padding-top:80px;
  padding-bottom:80px; 
}

#page-nav .inner {
  display: block;
  width: auto;
  height: auto;
  text-align:left;
}

#page-nav .text-align-center .inner {
      text-align:center;
  }

.page-title {
  margin:0;
  width:auto;
  z-index:10;
  color:#000;
  position:relative;
  display:table;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.text-align-center .page-title {
  margin:0 auto;
}

.light-content .page-title {
  color:#fff;
}

.next-hero-title {
  font-size: calc(1rem + 24.15vw);
  line-height: calc(1rem + 14.5vw);
  font-weight: 500;
  position: relative;
  display:block;
  width:auto;
  overflow:hidden;
  padding: 0;		
  margin-top:0px;
  margin-left:-0.6vw;
  color:#000;				
}

.height-title .next-hero-title {
  position:relative;
  overflow:visible;
  white-space: nowrap;
}

.text-align-center .next-hero-title, .text-align-center .next-hero-subtitle {
  display:table;
  width:auto;
  margin-left:auto;
  margin-right:auto;
  text-align:center;
}

.height-title .next-hero-title div {
  display:inline-block;
}

.height-title:not(.text-align-center) .next-hero-title div:first-child {
  display:none;
}

.next-hero-title span {
  display: block;
  min-width:3vw;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
}

.height-title .next-hero-title span {
  box-sizing:border-box;
  display:inline-block;
  transform-origin: top center;
}

.light-content .next-hero-title {
  color:#fff;		
}

.change-header .next-hero-title {
  color:#000;		
}

.next-hero-subtitle {
  font-size: 18px;
  font-weight:400;
  line-height:30px;
  margin-top:0px;
  margin-bottom:40px;
  width: 100%;
  z-index: 10;		
  position:relative;
  display:block;
  overflow:hidden;
  margin-left: 0px;
  color:#000;
  opacity:0.4;
}

#page-nav .next-hero-subtitle {
  margin-top: 0px;
  margin-bottom:50px;
}

.next-hero-subtitle span {
  position:relative;
  display:block;
}

.inline-title .next-hero-subtitle span {
  display: inline-block;
}

.light-content .next-hero-subtitle {
  color: #fff;
}

.change-header .next-hero-subtitle {
  color: #000;
}

/*--------------------------------------------------
Project Page Navigation
---------------------------------------------------*/	

#project-nav {
  height: 200vh;
  width: 100%;
  color: #fff;
  position: absolute;
  margin-bottom: 0;
  bottom: -200vh;
  overflow:hidden;
}

#project-nav.change-header {
  color: #000;
  background-color:#fff;
}

.dark-content #project-nav.change-header {
  color: #fff;
  background-color:#000;
}

.next-project-wrap {
  display: table;
  width: 100%;
  margin: 0 auto;
  height: 50%;
  position: absolute;
  box-sizing: border-box;
  opacity:1;
  /*top:-50%;*/
  z-index:10;
}

.hero-below-caption .next-project-wrap {
  top:0;
}

.next-project-image-wrapper {
  position: absolute;
  width: 100vw;
  height: 100%;
  top:0;
  left:0;
  opacity:1;
}

.next-project-image-wrapper.temporary {
  position:fixed;
}

.next-project-image-wrapper.active {
  opacity:1;
}

.next-project-image {
  position: absolute;
  top:0;
  opacity:1;
  left: 0;
  right:0;
  margin:0 auto;
  height: 100%;
  width: 100%;
  overflow:hidden;
  clip-path: inset(100% 0% 0% 0%);		
  -webkit-transform: scale(1.25) rotate(-5deg);
  transform: scale(1.25) rotate(-5deg);	
}

.load-project-thumb-with-title .next-project-image {
  z-index:10;
}

.next-project-image.temporary {
  top:0px!important;
}

.next-project-image.visible {
  opacity:1!important;
  clip-path: inset(0 0%)!important;
  -webkit-transform: scale(1.02) rotate(0)!important;
  transform: scale(1.02) rotate(0)!important;	
}

.next-project-image-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position:center center;
  background-repeat: no-repeat;
  z-index:1;
  -webkit-transform: scale(1);
  transform: scale(1);	
}

.next-project-image.visible .next-project-image-bg {
  opacity:1!important;
  top: 0!important;	
}

.all-works {
  position: absolute;
  top: 20px;
  left: 50%;
    -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: auto;
  margin: 0 auto;
  margin-top: 25px;
  display: table;
  z-index:100;
}

.all-works::after {
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  left: 0px;
  line-height: 30px;
  opacity: 1;
  -webkit-transition: all 0.15s ease-out 0s;
  transition: all 0.15s ease-out 0s;
  width: 100%;
  border: 2px solid #fff;
  border-radius: 30px;
  box-sizing: border-box;
  clip-path: inset(50% 0% );
  pointer-events: none;
}

.change-header .all-works::after {
  border: 2px solid #000;
}

.dark-content .change-header .all-works::after {
  border: 2px solid #fff;
}

.all-works:hover::after {
  clip-path: inset(0% 0% );
}

.all-works span, .all-works span::before {
  padding:0 16px;
}

.next-project-caption {
  position:absolute;
  display: table;
  left:0;
  right:0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top:20px;
  padding-bottom: 20px;
}

.next-caption-wrapper {
  display: table-cell;
  box-sizing: border-box;
  vertical-align: middle;
  text-align:left;
  position:relative;
}

#project-nav .caption-wrapper {
  position: relative;
  display: table;
  margin: 0;
}

#project-nav .text-align-center .caption-wrapper {
  margin: 0 auto;
  text-align:center;
}

.next-caption {
  display:table;
  position:relative;
  z-index:1;
  -webkit-transform: scale(1);
  transform: scale(1);
  transform-origin: center center;		
}

.text-align-center .next-caption {
  margin: 0 auto;
  text-align:center;
}	

#project-nav .next-ajax-link-project {
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
}

.next-ajax-link-project.auto-trigger {
  pointer-events:none;
}

.disable-ajaxload .next-ajax-link-project.auto-trigger {
  pointer-events:initial;
}

.next-hero-counter {
  position: absolute;
  height: 20px;
  width:30px;
  text-align:right;
  top:6px;
  right:-45px;
  overflow:hidden;
  opacity:0;
}

.next-hero-counter span {
  position:absolute;
  display:block;
  font-size: 14px;
  font-weight:500;
  line-height:20px;
  color:#000;
}

.change-header .next-hero-counter span {
  color: #000;
}

.light-content .next-hero-counter span {
  color: #fff;
}

.light-content .change-header .next-hero-counter span {
  color: #000;
}

.dark-content .change-header .next-hero-counter span {
  color: #fff;
}

.next-hero-progress {
  position: absolute;
  top:0;
  right:0;
  height: 6px;
  width: 100%;
  z-index:100;
}

.next-hero-progress span {
  position: absolute;
  left:0;
  height: 6px;
  width: 0;
  background-color: #000;
}

.change-header .next-hero-progress span {
  background-color: #000;
}

.light-content .next-hero-progress span {
  background-color: #fff;
}

.light-content .change-header .next-hero-progress span {
  background-color: #000;
}

.dark-content .change-header .next-hero-progress span {
  background-color: #fff;
}




#project-nav .next-hero-title, #project-nav .next-hero-subtitle {
  color:#000;
}

#project-nav.change-header .next-hero-title, #project-nav.change-header .next-hero-subtitle {
  color:#000;
}

.light-content #project-nav .next-hero-title, .light-content #project-nav .next-hero-subtitle {
  color:#fff;
}

.light-content #project-nav.change-header .next-hero-title, .light-content #project-nav.change-header .next-hero-subtitle {
  color:#000;
}

.dark-content #project-nav.change-header .next-hero-title, .dark-content #project-nav.change-header .next-hero-subtitle {
  color:#fff;
}





#project-nav.change-header .all-works, #project-nav.change-header .all-works .link-text {
  color:#000;
}

.light-content #project-nav .all-works, .light-content #project-nav .all-works .link-text {
  color:#fff;
}

.light-content #project-nav.change-header .all-works, .light-content #project-nav.change-header .all-works .link-text {
  color:#000;
}

.dark-content #project-nav.change-header .all-works, .dark-content #project-nav.change-header .all-works .link-text {
  color:#fff;
}



/*--------------------------------------------------
Temporary Hero
---------------------------------------------------*/	

.temporary-hero {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  pointer-events: none;
  z-index:10;
}

.temporary-hero .outer {
  width: 100%;
  padding-top:120px;
  padding-bottom:160px;
  height: 100%;
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  z-index:10;
}

.temporary-hero .outer.middle {
  padding-top:160px;
  padding-bottom:160px;
}

.hero-below-caption .temporary-hero .outer {
  padding-top:315px;
}

.temporary-hero .inner {
  vertical-align: top;
}

.temporary-hero .middle .inner {
  vertical-align: middle;
}

.hero-below-caption .temporary-hero .inner {
  vertical-align: top;
  text-align:left;
}

.temporary-hero .inner .caption-wrapper, .temporary-hero .inner .next-caption {
  position: relative;
}

.temporary-hero .inner .caption-wrapper .title span {
  color: #000;
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.temporary-hero .inner .caption-wrapper .subtitle span {
  position: relative;
  display: block;
  opacity: 1;
  color: #000;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
}

.temporary-hero.light-content .inner .caption-wrapper .title span, .temporary-hero.light-content .inner .caption-wrapper .subtitle span {
  color: #fff;
}

.temporary-hero .next-hero-progress {
  background-color: rgba(0,0,0,0.3);
}

.temporary-hero .next-hero-progress span {
  background-color: #000;
}

.temporary-hero.light-content .next-hero-progress {
  background-color: rgba(255,255,255,0.3);
}

.temporary-hero.light-content .next-hero-progress span {
  background-color: #fff;
}

.temporary-hero .next-hero-counter span {
  color: #000;
}

.temporary-hero.light-content .next-hero-counter span {
  color: #fff;
}

.temporary-hero .inner .caption-wrapper .title div:first-child span:first-child::before {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out 0.2s;
  transition: opacity 0.3s ease-out 0.2s;
}

.temporary-hero .inner .caption-wrapper .title div:first-child span:first-child::after {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out 0.2s;
  transition: opacity 0.3s ease-out 0.2s;
}


/*--------------------------------------------------
General Width and Padding for Hero, Content, Page and Project Nav
---------------------------------------------------*/


.content-max-width {
  box-sizing:border-box;
  padding-left:80px;
  padding-right:80px;
  max-width:1320px;
}

.content-full-width {
  box-sizing:border-box;
  padding-left:80px;
  padding-right:80px;
  max-width:100%;
}


/*--------------------------------------------------
Responsive
---------------------------------------------------*/	
@media only screen and (max-width: 1537px) {

.hero-title {
  font-size: calc(1rem + 22.15vw);
  line-height: calc(1rem + 20.5vw);
}

.hero-subtitle {
    margin-top: 30px;
}

.hero-text {
    font-size: 26px;
  line-height: 36px;
}

#page-nav .next-hero-subtitle {
  margin-bottom: 30px;
}

.next-hero-title {
  font-size: calc(1rem + 22.15vw);
  line-height: calc(1rem + 12.5vw);
}

}



@media only screen and (max-width: 1466px) {

#hero-caption {
  padding-top:160px;
  padding-bottom:160px;
}

#hero.has-image #hero-caption, .temporary-hero .outer {	
  padding-bottom: 60px;
}

.temporary-hero .outer.middle {
    padding-top: 140px;
  padding-bottom: 140px;
}

#hero-description {
  padding-bottom: 220px;
}

#hero-description .inner {
    padding-right: 45%;
}

#hero-footer {
    padding: 25px 60px;
}

.page-nav-caption {
  padding-top: 160px;
  padding-bottom: 120px;
}

.content-max-width, .content-full-width {
  padding-left:60px;
  padding-right:60px;
}

}

@media only screen and (max-width: 1024px) {

#hero-caption {
  padding-top:140px;
  padding-bottom:140px;
}

#hero.has-image #hero-caption, .temporary-hero .outer {	
  padding-bottom: 40px;
}	
.hero-subtitle {
  margin-top: 20px;
  font-size: 18px;
}

#hero-description {
  padding-bottom: 200px;
}

#hero-description .inner {
    padding-right: 35%;
}

#hero-footer {
    padding: 10px 40px;
}

.page-nav-caption {
  padding-top: 140px;
  padding-bottom: 100px;
}

#page-nav .next-hero-subtitle {
  margin-top: 20px;
}

.next-hero-subtitle {
    font-size: 18px;
}

.next-hero-counter {
  top: 0px;
}

.content-max-width, .content-full-width {
  padding-left:40px;
  padding-right:40px;
}

}

@media only screen and (max-width: 767px) {	

#hero-caption {
  padding-top:120px;
  padding-bottom:120px;
}

#hero.has-image #hero-caption, .temporary-hero .outer {	
  padding-bottom: 30px;
}

.temporary-hero .outer.middle {
    padding-top: 120px;
  padding-bottom: 120px;
}

.hero-subtitle {
    margin-top: 10px;
  font-size: 16px;
}

.subtitle-padding-left .hero-subtitle {
  margin-left: 0;
  margin-right: 0;
  margin: auto;
  margin-top: 10px;
  padding-left: 0;
  text-align: center;	
}

.hero-arrow {
  width: 30px;
  height: 45px;
  left: 30px;
  bottom:38px;
}

#hero-description {
  padding-bottom: 180px;
}

#hero-description .inner {
    padding-right: 15%;
}

.hero-text {
  font-size: 22px;
  line-height: 32px;
}

#hero-footer {
  padding: 10px 30px;
}

.hero-footer-left {
  transform: translateX(-20px);
  -webkit-transform: translateX(-20px);
}

.hero-footer-left .button-text {
  display:none;
}

.page-nav-caption {
  padding-top: 120px;
  padding-bottom: 120px;
}

#page-nav .next-hero-subtitle {
  margin-top: 10px;
    font-size: 16px;
}

.next-hero-counter {
  top: -2px;
}

.content-max-width, .content-full-width {
  padding-left:30px;
  padding-right:30px;
}

}

@media only screen and (max-width: 479px) {

#hero-caption {
  padding-top:100px;
  padding-bottom:100px;
}

#hero.has-image #hero-caption, .temporary-hero .outer {	
  padding-bottom: 20px;
}

#hero-description {
  padding-bottom: 160px;
}

#hero-description .inner {
    padding-right: 0;
}

#hero-footer {
  padding: 10px 20px;
}

#hero .button-wrap .button-text {
  display: none;
}

.page-nav-caption {
  padding-top: 100px;
  padding-bottom: 100px;
}

.all-works {
    top: 0;
}

.next-hero-counter {
  top: -4px;
  right: -38px;
}

.content-max-width, .content-full-width {
  padding-left:20px;
  padding-right:20px;
}
  
}


.hero-subtitle span.uppercase {
text-transform: uppercase;
}

