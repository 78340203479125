@import url("content.css");
@import url("showcase.css");
@import url("shortcodes.css");
@import url("assets.css");
@import url("custom.css");
	
/*--------------------------------------------------
	01. General Settings
---------------------------------------------------*/

	html,body{
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
		font-size:16px;
		height:100%;
		width:100%;
		color:#444;
		background-color:rgb(12, 12, 12);
	}
	
	html {
		box-sizing: border-box;
		margin-right: 0px!important;
		overflow: visible!important;
	}
	
	body {
		overflow: hidden;
		overflow-y:scroll;
		-webkit-transition: opacity 0.2s ease-in-out 0.2s;
		transition: opacity 0.2s ease-in-out 0.2s;
	}
	
	body.smooth-scroll {
		overflow:hidden;
	}
	
	body.has-slider {
		overflow:hidden;
	}
	
	body.hidden {
		opacity:0;
	}
	
	main111 {
		-webkit-transition: background 0.5s ease-in-out 0s;
		transition: background 0.5s ease-in-out 0s;
	}
	
	html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
		border: 0;
		margin: 0;
		outline: 0;
		padding: 0;
		vertical-align: baseline;
	}
	
	article,
	aside,
	details,
	figcaption,
	footer,
	header,
	hgroup,
	nav,
	section {
		display: block;
	}
	
	audio {
		display: inline-block;
		max-width: 100%;
	}	
	
	address {
		font-style: italic;
		margin-bottom: 24px;
	}
	
	abbr[title] {
		border-bottom: 1px dotted #2b2b2b;
		cursor: help;
	}
	
	b,
	strong {
		font-weight: 600;
		color:#000;
	}
	
	.light-content b, .light-content strong, .dark-section b, .dark-section strong {
		font-weight: 600;
		color:#fff;
	}
	
	.light-content .light-section b, .light-content .light-section strong {
		font-weight: 600;
		color:#000;
	}
	
	cite,
	dfn,
	em,
	i {
		font-style: italic;
	}
	
	mark, ins {
		background: none repeat scroll 0 0 #000;
		text-decoration: none;
		color: #fff;
		padding: 2px 8px;
		display: inline-block;
		border-radius: 2px;
	}
	
	.light-content mark, .light-content ins, .dark-section mark, .dark-section ins {
		background: none repeat scroll 0 0 #fff;
		color:#000;
	}
	
	.light-content .light-section mark, .light-content .light-section ins {
		background: none repeat scroll 0 0 #111;
		color:#fff;
	}
	
	code,
	kbd,
	tt,
	var,
	samp,
	pre {
		font-family: monospace, serif;
		font-size: 15px;
		-webkit-hyphens: none;
		-moz-hyphens:    none;
		-ms-hyphens:     none;
		hyphens:         none;
		line-height: 1.6;
	}
	
	pre {
		-webkit-box-sizing: border-box;
		-moz-box-sizing:    border-box;
		box-sizing:         border-box;
		margin-bottom: 24px;
		max-width: 100%;
		overflow: auto;
		padding: 12px;
		white-space: pre;
		white-space: pre-wrap;
		word-wrap: break-word;
		background-color:rgba(0,0,0,0.05)
	}
	
	.light-content pre, .dark-section pre {
		background-color:rgba(255,255,255,0.05);
		color:#fff;
	}
	
	.light-content .light-section pre {
		background-color:rgba(0,0,0,0.05);
		color:#000;
	}
	
	blockquote,
	q {
		-webkit-hyphens: none;
		-moz-hyphens:    none;
		-ms-hyphens:     none;
		hyphens:         none;
		quotes: none;
	}
	
	blockquote:before,
	blockquote:after,
	q:before,
	q:after {
		content: "";
		content: none;
	}
	
	blockquote {
		color: #000;
		line-height: 1.6;
		font-size: 1.5rem;
		margin-bottom: 2.1875rem;
		font-weight: 500;
		padding-left: 3.5rem;
		margin: 0 0 1.5625rem;
		position:relative;
	}
	
	.light-content blockquote, .dark-section blockquote {
		color: #fff;
	}
	
	.light-content .light-section blockquote {
		color: #000;
	}
	
	blockquote::before {
		content: "\275D";
		position: absolute;
		left: 0;
		top: 5px;
		line-height: 1;
		font-size: 3rem;
		font-weight: 500;
	}
	
	blockquote cite,
	blockquote small {
		color: #2b2b2b;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5;
	}
	
	.light-content blockquote cite, .light-content blockquote small {
		color:#fff;
	}
	
	blockquote em,
	blockquote i,
	blockquote cite {
		font-style: normal;
	}
	
	blockquote strong,
	blockquote b {
		font-weight: 600;
	}
	
	small {
		font-size: smaller;
	}
	
	big {
		font-size: 125%;
	}
	
	sup,
	sub {
		font-size: 75%;
		height: 0;
		line-height: 0;
		position: relative;
		vertical-align: baseline;
	}
	
	sup {
		bottom: 1ex;
	}
	
	sub {
		top: .5ex;
	}
	
	dl {
		margin-bottom: 24px;
	}
	
	dt {
		font-weight: bold;
	}
	
	dd {
		margin-bottom: 24px;
	}
	
	ul,
	ol {
		list-style: none;
		margin: 0 0 24px 20px;
	}
	
	ul,
	ol {
		list-style: none;
		margin:20px 0 25px 20px;
	}
	
	ul {
		list-style: disc;
	}
	
	ol {
		list-style: decimal;
	}
	
	li > ul,
	li > ol {
		margin: 20px 0px 15px 25px;
	}
	
	li {
		line-height: 20px;
		color:#444;
		margin-bottom:25px;
	}
	
	.light-content li, .dark-section li {
		color: #ccc;
	}
	
	.light-content .light-section li {
		color: #444;
	}
	
	figure {
		margin:0;
		position:relative;
		display: block;
	}
	
	figure.has-parallax {		
		overflow:hidden;
	}
	
	.full figure.has-parallax {		
		overflow:hidden;
		height:100vh;
	}
	
	figure img {
		max-width:100%;
	}
	
	.has-parallax-content > img, .has-parallax > img {
		width:100%;
		height: 100%;
		position:absolute;
		display:block;
		object-position: center; 
		object-fit: cover;
	}
	
	figcaption {
		background-color: rgba(0,0,0,0.3);
		bottom: 0px;
		color: #fff;
		font-weight:400;
		font-size: 10px;
		padding: 5px 10px;
		position: absolute;
		right: 0px;
		z-index: 10;
		border-radius:0;
	}
	
	fieldset {
		border: 1px solid rgba(0, 0, 0, 0.1);
		margin: 0 0 24px;
		padding: 0;
	}
	
	legend {
		white-space: normal;
	}
	
	button,	input {
		line-height: normal;
	}
	
	input,
	textarea {
		background-image: -webkit-linear-gradient(hsla(0,0%,100%,0), hsla(0,0%,100%,0)); /* Removing the inner shadow, rounded corners on iOS inputs */
	}
	
	button, html input[type="button"], input[type="reset"], input[type="submit"] {
		cursor: pointer;
	}
	
	button[disabled],
	input[disabled] {
		cursor: default;
	}
	
	input[type="checkbox"],
	input[type="radio"] {
		padding: 0;
	}
	
	input[type="search"] {
		-webkit-appearance: textfield;
	}
	
	input[type="search"]::-webkit-search-decoration {
		-webkit-appearance: none;
	}
	
	button::-moz-focus-inner, input::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
	
	textarea {
		overflow: auto;
		vertical-align: top;
	}
	
	table {
		border-collapse: separate;
		border-spacing: 0;
		border-width: 1px 0 0 1px;
		margin-bottom: 24px;	
		width: 100%;
		color:#444;
	}
	
	thead, .light-content .light-section thead {
		background-color:rgba(0,0,0,1);
		color:#fff;
	}
	
	.light-content thead, .dark-section thead {
		background-color:rgba(255,255,255,1);
	}
	
	thead tr, .light-content .light-section thead tr {
		color:#fff;
	}
	
	.light-content thead tr, .dark-section thead tr {
		color:#000;
	}
	
	tr, .light-content .light-section tr {
		color:#444;
	}
	
	.light-content tr, .dark-section tr{
    	color:#CCC;
	}
	
	tr:nth-child(2n), .light-content .light-section tr:nth-child(2n) {
    	background-color:rgba(0,0,0,0.05);
	}
	
	.light-content tr:nth-child(2n), .dark-section tr:nth-child(2n){
    	background-color:rgba(255,255,255,0.1);
	}
	
	caption, th, td {
		font-weight: normal;
		text-align: left;
	}
	
	th {
		border-width: 0 1px 1px 0;
		padding: 10px;
	}
	
	td {
		border-width: 0 1px 1px 0;
		padding: 10px;
	}
	
	del {
		color: #767676;
	}
	
	hr {
		border: 0;
		height: 28px;
		width:100%;
		float:none;
		margin:0;
		display:inline-block;
		opacity: 1!important;
		-webkit-transition: translateY(0px)!important;
		transform: translateY(0px)!important;
	}
	
	hr.animated-line {
		height:1px;		
		padding-top:20px;
		padding-bottom:20px;
	}
	
	hr.animated-line:before {
		content: ""; 
		display: block; 
		width:0;
		height:1px;
		background:rgba(0,0,0,0.1);
	}
	
	.light-content hr.animated-line:before, .dark-section hr.animated-line:before {
		background:rgba(255,255,255,0.15);
	}
	
	.light-content .light-section hr.animated-line:before {
		background:rgba(0,0,0,0.1);
	}
	
	hr.animated.animated-line:before {
		width:100%;
		-webkit-transition: width 0.7s ease-in-out 0.1s;
		transition: width 0.7s ease-in-out 0.1s;
	}
	
	hr:after {
		clear: both;
		content: " ";
		display: block;
		height: 0;
		visibility: hidden;
	}
	
	::selection {
		background: #000;
		color: #fff;
		text-shadow: none;
	}
	
	::-moz-selection {
		background: #000;
		color: #fff;
		text-shadow: none;
	}
	
	img {
		border: 0 none;
		max-width: 100%;
		vertical-align: middle;
		height:auto;
	}
		
	h1, h2, h3, h4, h5, h6 {		
		font-family:inherit;
		font-weight:500;
		color:#000;
		margin-bottom:20px;
	}
	
	.primary-font-title {
		font-family: 'Six Caps', sans-serif;
		text-transform:lowercase;
		font-weight:500;
	}
	
	.big-title {
		font-size: calc(1rem + 8vw);
  		line-height: calc(1rem + 7.5vw);
	}
	
	.fixed-title {
		font-size: calc(1rem + 8vw);
  		line-height: calc(1rem + 7.5vw);
		margin-bottom:100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
	}
	
	.fixed-title.primary-font-title {
		font-size: calc(1rem + 24.15vw);
		line-height: calc(1rem + 22vw);
	}
	
	.fixed-title div {
		display:inline-block;		
		white-space: nowrap;
	}
	
	.fixed-title span {
		display: block;
		opacity:0.1;
		margin-top:0;
		min-width:3rem;
		box-sizing:border-box;
		display:inline-block;
		transform-origin: bottom center;
	}
	
	
	h1{
		font-size: calc(1rem + 4.15vw);
		line-height: calc(1rem + 4.5vw);
	}
	
	h2{
		font-size: calc(1rem + 3vw);
		line-height: calc(1rem + 3.5vw);
	}
	
	h3{
		font-size: calc(1rem + 2vw);
		line-height: calc(1rem + 2.5vw);
	}
	
	h4{
		font-size: calc(1rem + 1vw);
		line-height: calc(1rem + 1.6vw);
	}
	
	h5{
		font-size: calc(1rem + 0.6vw);
		line-height: calc(1rem + 1.5vw);
	}
	
	h6 {
		font-size:18px;
		line-height: 36px;
	}
	
	h6.has-icon {
		padding-left: 20px;
		box-sizing: border-box;
		display: inline-block;
		width: auto;
		position: relative;
	}
	
	h6.has-icon::before {
		font: var(--fa-font-solid);
		content: "\f101";
		font-size: 12px;
		line-height: inherit;
		text-align: left;
		position: absolute;
		left: 0;
	}
	
	.no-margins {
		margin:0;
	}
	
	.light-content h1, .light-content h2, .light-content h3, .light-content h4, .light-content h5, .light-content h6, .dark-section h1, .dark-section h2, .dark-section h3, .dark-section h4, .dark-section h5, .dark-section h6 {
		color: #fff;
	}
	
	.light-content .light-section h1, .light-content .light-section h2, .light-content .light-section h3, .light-content .light-section h4, .light-content .light-section h5, .light-content .light-section h6 {
		color: #000;
	}
	
	p {
		font-size:18px;
		line-height: 28px;
		font-weight:500;
		margin-bottom: 20px;
		color:#999;
	}
	
	.light-content p, .dark-section p {
		color:#999;
	}
	
	.light-content .light-section p {
		color:#999;
	}
	
	.bigger {
		font-size: 26px;
		line-height: 42px;
	}
	
	.smaller {
		font-size: 12px;
		line-height: 24px;
	}
	
	.container {
		max-width:1280px;
		width:100%;
		margin:0 auto;
		box-sizing:border-box;
	}
	
	.container::after {
		clear: both;
		content: " ";
		display: table;
	}
	
	a:hover, a:active {
	  outline: 0;
	  color: #000;
	}
	
	a, .light-content .light-section a {
		text-decoration: none;
		color: #000;
		outline: 0;
	}
	
	.light-content a, .dark-section a, .white-header a {
		color: #fff;
	}
	
	.light-content .white-header a { 
		color:#000;		
	}
	
	a:hover {
		text-decoration:none;
	}
	
	.one_half {
    	width: 48%!important;
	}	

	.one_third {
		width: 30.6%!important;
	}	

	.one_fourth {
		width: 22%!important;
	}	

	.one_fifth {
		width: 16.8%!important;
	}	

	.one_sixth {
		width: 13.33%!important;
	}	

	.two_fifth {
		width: 37.6%!important;
	}	

	.two_fourth {
		width: 48%!important;
	}		

	.two_third {
		width: 65.33%!important;
	}	

	.three_fifth {
		width: 58.4%!important;
	}	

	.three_fourth {
		width: 74%!important;
	}	

	.four_fifth {
		width: 79.2%!important;
	}	

	.five_sixth {
		width: 82.67%!important;
	}	

	.one_half, .one_third, .two_third, .three_fourth, .one_fourth, .two_fourth, .one_fifth, .two_fifth, .three_fifth, .four_fifth, .one_sixth, .five_sixth {
		float: left!important;
		margin-bottom: 1px!important;
		margin-right: 4%!important;
		position: relative!important;
	}

	.last {
		margin-right:0px!important;
	}
	
	.text-align-left {
		text-align:left;
	}
	
	.text-align-center {
		text-align:center;
	}
	
	.text-align-right {
		text-align:right;
	}
	
	.outer {
		display:table;
		width:100%;
		height:100%;
	}
	
	.inner {
		display:table-cell;
		vertical-align:middle;
		-webkit-box-sizing:border-box;
		-moz-box-sizing:border-box;
		box-sizing:border-box;
	}
	
	.light-section-wrapper, .dark-section-wrapper {
		margin-left: calc(50% - 50vw)!important;
		margin-right: calc(50% - 50vw)!important;
		max-width: 1000%!important;
		width: 100vw!important;
	}
	
	.light-section-container, .dark-section-container {
		position: relative;
		box-sizing: border-box;
		width: 100%;
		margin: 0 auto;
	}
	
	.content-row {
		position:relative;
		width:100%;
		margin:0 auto;
		z-index:1;
		opacity:1;
		box-sizing:border-box;
	}
	
	.clip-effects {
		overflow:hidden;
		clip-path: inset(4% 10% round 40px);
	}
	
	.content-row::after {
		clear: both;
		content: " ";
		display: table;
	}
	
	.small {
		max-width:820px;
		width:100%;
		margin:0 auto;
	}
	
	.large {
		max-width:1380px;
		width:100%;
		margin:0 auto;
	}
	
	.full {
		margin-left: calc(50% - 50vw)!important;
		margin-right: calc(50% - 50vw)!important;
		max-width: 1000%!important;
		width: 100vw!important;
	}
	
	.row_padding_top {
		padding-top:9vw;
	}
	
	.row_padding_bottom {
		padding-bottom:9vw;
	}
	
	.row_padding_left {
		padding-left:80px;
	}
	
	.row_padding_right {
		padding-right:80px;
	}
	
	.row_padding_all {
		padding-top: 4vw;
		padding-bottom: 4vw;
		padding-left: 80px;
		padding-right: 80px;
	}
	
	#rotate-device {
		width:100%;
		height:100%;
		position:fixed;
		z-index:1000;
		top:0;
		left:0;
		background-color:#0f1010;
		/* background-image:url(images/rotate.png); */
		background-size:100px 100px;
		background-position:center;
		background-repeat:no-repeat;
		display:none;
	}
	
	i.arrow-icon {
		border: solid #000;
		border-width: 0px 3px 3px 0px;
		box-sizing:border-box;
		display: block;
		position:relative;
		top:0px;
		left:0px;
		height:20px;
		width:20px;
	}
	
	.light-content i.arrow-icon, .dark-content .item i.arrow-icon {
		border: solid #fff;
		border-width: 0px 3px 3px 0px;
	}
	
	.light-content .change-header i.arrow-icon, .dark-content .change-header i.arrow-icon {
		border: solid #000;
		border-width: 0px 3px 3px 0px;
	}
	
	i.arrow-icon::after {
		content: "";
		position: absolute;
		width: 28px;
		height: 3px;
		top: 7px;
		left: -6px;
		background-color: #000;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
	}
	
	.light-content i.arrow-icon:after, .light-content .change-header i.arrow-icon::after, .dark-content .item i.arrow-icon::after {
		background-color:#fff;
	}
	
	.light-content .change-header i.arrow-icon::after, .dark-content .change-header i.arrow-icon::after {
		background-color:#000;
	}
	
	i.arrow-icon-down {
		border: solid #222;
		border-width: 0px 2px 2px 0px;
		box-sizing:border-box;
		display: block;
		position:relative;
		top:14px;
		left:14px;
		height: 12px !important;
		width: 12px !important;
		line-height: 12px !important;
	}
	
	i.arrow-icon-down.left {
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
	}
	
	i.arrow-icon-down::after {
		content: "";
		position: absolute;
		width: 16px;
		height: 2px;
		top: 4px;
		left: -3px;
		background-color: #222;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		
	}
	
	.light-content i.arrow-icon-down {
		border: solid #fff;
		border-width: 0px 2px 2px 0px;
	}
	
	.light-content i.arrow-icon-down::after {
		background-color: #fff;
	}
	
	i.arrow-icon-up {
		border: solid #222;
		border-width:  2px 2px 0px 0px;
		box-sizing:border-box;
		display: block;
		position:relative;
		top:14px;
		left:14px;
		height: 12px !important;
		width: 12px !important;
		line-height: 12px !important;
	}	
	
	i.arrow-icon-up.right {
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
	}	
	
	i.arrow-icon-up::after {
		content: "";
		position: absolute;
		width: 16px;
		height: 2px;
		top: 4px;
		left: -3px;
		background-color: #222;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
	}
	
	.light-content i.arrow-icon-up {
		border: solid #fff;
		border-width:  2px 2px 0px 0px;
	}
	
	.light-content i.arrow-icon-up::after {
		background-color: #fff;
	}

/*--------------------------------------------------
	02. Magic Cursor
---------------------------------------------------*/	
	
	
	#magic-cursor {
	  position: absolute;
	  left:0;
	  top:0;
	  width: 30px;
	  height: 30px;
	  pointer-events: none;
	  z-index:10000;
	  -webkit-transition: opacity 0.2s ease-in-out 0.5s;
		transition: opacity 0.2s ease-in-out 0.5s;
	}
	
	.hidden-ball #magic-cursor {
		opacity:0!important;
		visibility:hidden;
	}
	
	#ball {
		position: fixed;
		width: 80px;
		height: 80px;
		border: 2px solid #999999;
		border-radius: 50%;
		pointer-events: none;
		opacity:1;
		box-sizing:border-box;
		 -webkit-transform: scale(0.5);
	  	transform: scale(0.5);	
	}
	
	.light-content #ball, #ball.over-movie,  .light-content #ball.with-icon {
		border: 2px solid #999999;
		border-color:#999999;
	}
	
	#ball.with-blur {
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);		
	}
	
	#ball:before {
		font: var(--fa-font-solid);
		content: "\f106";
		font-size:14px;
		width:8px;
		height:16px;
		line-height:16px;
		text-align:center;
		position:absolute;
		left: 32px;
		top: -42px;
		color:#999;
		opacity:0;
		transform: translateY(-20px);
		-webkit-transform: translateY(-20px);
		transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.light-content #ball:before {
		color:#999;
	}
	
	#ball::after {
		font: var(--fa-font-solid);
		content: "\f107";
		font-size: 14px;
		width: 8px;
		height: 16px;
		line-height: 16px;
		text-align: center;
		position: absolute;
		left: 32px;
		top: 100px;
		color: #999;
		opacity:0;
		transform: translateY(20px);
		-webkit-transform: translateY(20px);
		transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.light-content #ball:before, .light-content #ball:after {
		color:#999;
	}
	
	.scale-up #ball:before, .scale-drag-horizontal #ball:before {
		opacity:1;
		transform: translateY(0px);
		-webkit-transform: translateY(0px);
		transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.scale-drag-horizontal #ball:before {
		color: #fff;
	}
	
	.scale-up #ball:after, .scale-drag-horizontal #ball:after {
		opacity:1;
		transform: translateY(0px);
		-webkit-transform: translateY(0px);
		transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.scale-drag-horizontal #ball:after {
		color: #fff;
	}
	
	.scale-up.scale-none #ball:before, .scale-up.scale-none #ball:after {
		opacity:0;
	}
	
	#ball-drag-x {
		position: absolute;
		width: 72px;
		height: 72px;
		pointer-events: none;
		opacity:1;
		box-sizing:border-box;	
	}
	
	#ball-drag-x::before {
		font: var(--fa-font-solid);
		content: "\f053";
		font-size: 14px;
		width: 8px;
		height: 16px;
		line-height: 16px;
		text-align: center;
		position: absolute;
		left: -37px;
		top: 30px;
		color: #000;
		opacity: 0;
		transform: translateX(-20px);
		-webkit-transform: translateX(-20px);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.light-content #ball-drag-x:before {
		color:#fff;
	}
	
	#ball-drag-x::after {
		font: var(--fa-font-solid);
		content: "\f054";
		font-size: 14px;
		width: 8px;
		height: 16px;
		line-height: 16px;
		text-align: center;
		position: absolute;
		right: -40px;
		left: auto;
		top: 30px;
		color: #000;
		opacity: 0;
		transform: translateX(20px);
		-webkit-transform: translateX(20px);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.light-content #ball-drag-x:before, .light-content #ball-drag-x:after {
		color:#fff;
	}
	
	.drag-cursor-white #ball-drag-x:before, .drag-cursor-white #ball-drag-x:after {
		color:#fff;
	}
	
	.drag-cursor-black #ball-drag-x:before, .drag-cursor-black #ball-drag-x:after {
		color:#000;
	}
	
	.scale-drag-x #ball-drag-x:before {
		opacity:1;
		transform: translateX(0px);
		-webkit-transform: translateX(0px);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.scale-drag-x #ball-drag-x:after {
		opacity:1;
		transform: translateX(0px);
		-webkit-transform: translateX(0px);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.scale-up.scale-drag #ball-drag-x:before, .scale-up.scale-drag #ball-drag-x:after {
		opacity:0;
	}
	
	#ball-drag-y {
		position: absolute;
		width: 72px;
		height: 72px;
		pointer-events: none;
		opacity:1;
		box-sizing:border-box;	
	}
	
	#ball-drag-y:before {
		font: var(--fa-font-solid);
		content: "\f077";
		font-size:14px;
		width:8px;
		height:16px;
		line-height:16px;
		text-align:center;
		position:absolute;
		left: 32px;
		top: -40px;
		color:#000;
		opacity:0;
		transform: translateY(-20px);
		-webkit-transform: translateY(-20px);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.light-content #ball-drag-y:before {
		color:#fff;
	}
	
	#ball-drag-y::after {
		font: var(--fa-font-solid);
		content: "\f078";
		font-size: 14px;
		width: 8px;
		height: 16px;
		line-height: 16px;
		text-align: center;
		position: absolute;
		left: 32px;
		top: 100px;
		color: #000;
		opacity:0;
		transform: translateY(20px);
		-webkit-transform: translateY(20px);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.light-content #ball-drag-y:before, .light-content #ball-drag-y:after {
		color:#fff;
	}
	
	.scale-drag-y #ball-drag-y:before {
		opacity:1;
		transform: translateY(0px);
		-webkit-transform: translateY(0px);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.scale-drag-y #ball-drag-y:after {
		opacity:1;
		transform: translateY(0px);
		-webkit-transform: translateY(0px);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.scale-up.scale-drag #ball-drag-y:before, .scale-up.scale-drag #ball-drag-y:after {
		opacity:0;
	}
	
	#ball i {
		color:#fff;
		width:76px;
		height:80px;
		line-height:78px;
		text-align:center;
		font-size:14px;
		display:block;
		opacity:1;
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	#ball {
		display:flex;
		flex-direction: column;
		justify-content: center;
	}
	
	#ball p {
		color:#fff;
		width:auto;
		height:16px;
		line-height:16px;
		padding-top:0px;
		padding-left:0px;
		box-sizing:border-box;
		margin:0 auto;
		margin-bottom:0;
		text-align:center;
		font-size:10px;
		font-weight: 600;
		display:flex;
		flex-direction: column;
		align-self: center;
		opacity:1;
		position:relative;
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.light-content #ball p {
		color:#fff;
	}
	
	#ball p.center-first::before {
		content:"[";
		font-size:12px;
		font-weight: 500;
		position:absolute;
		height:20px;
		top:calc(50% - 10px);
		left:-8px;
		line-height:20px;
	}
	
	#ball p.center-first::after {
		content:"]";
		font-size:12px;
		font-weight: 500;
		position:absolute;
		height:20px;
		top:calc(50% - 10px);
		right:-8px;
		line-height:20px;
	}
	
	.light-content #ball i, #ball.over-movie i, .light-content #ball.with-icon i {
		color:#fff;
	}
	
	.drag-cursor-white #ball i, .drag-cursor-white #ball.with-icon i {
		color:#fff;
	}
	
	.drag-cursor-black #ball i, .drag-cursor-black #ball.with-icon i {
		color:#000;
	}
	
	.light-content #ball.with-icon.dark-icon i {
		color:#000;
	}
	
	.scale-up #ball i {
		opacity:0;
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	#ball.with-icon i {
		width:76px;
	}
	
	#ball.with-icon i:nth-child(2) {
		display:none;
	}
	
	#ball.close-icon i {
		width:75px;
		height:76px;
		font-size:14px;
	}
	
	#ball.over-movie i.fa-play, #ball.over-movie.pause-movie i.fa-pause {
		display:block;
	}
	
	#ball.over-movie i.fa-pause, #ball.over-movie.pause-movie i.fa-play {
		display:none;
	}
	
	#ball-loader {
		width: 100px;
		height: 100px;
		position:absolute;
		background-color: transparent;
		border-right: 4px solid transparent;
		border-bottom: 4px solid #999999;
		border-left: 4px solid transparent;
		border-top: 4px solid transparent;
		border-radius: 100px;
		box-sizing: border-box;
		opacity:0;
		-webkit-transform: translate(-14px, -14px) rotate(0deg);
		transform: translate(-14px, -14px) rotate(0deg);
		-webkit-animation: rotating 0.8s ease-in-out infinite;
		animation: rotating 0.8s ease-in-out infinite;
		-webkit-transition: opacity 0s ease-in-out 0s;
		transition: opacity 0s ease-in-out 0s;
	}
	
	.light-content #ball-loader { 
		border-bottom: 4px solid #999999;
	}
	
	.show-loader #ball-loader {
		opacity:1;
		-webkit-transition: opacity 0.2s ease-in-out 0s;
		transition: opacity 0.2s ease-in-out 0s;
	}
	
	@keyframes rotating {
      0% {
		-webkit-transform: translate(-14px, -14px)rotate(0deg);
		transform:  translate(-14px, -14px) rotate(0deg);
      }
	  
	  95% {
        -webkit-transform:  translate(-14px, -14px) rotate(350deg);
		transform:  translate(-14px, -14px) rotate(350deg);
      }
	  
      100% {
        -webkit-transform:  translate(-14px, -14px) rotate(360deg);
		transform:  translate(-14px, -14px) rotate(360deg);
      }
	}
	
	.show-loader a, .show-loader nav, .scale-up a, .scale-up .hide-ball, scale-up .link, .scale-up .button-wrap, .scale-up #burger-wrapper, .scale-up .disable-drag, .scale-drag a, .scale-drag .move-title {
		pointer-events:none!important;
	}
	
	.disable-cursor #magic-cursor {
		display:none;
	}
	
	#ball i.arrow-icon {
		border: solid #fff;
		border-width: 3px 3px 0px 0px;
		box-sizing:border-box;
		display: block;
		position:absolute;
		top:28px;
		left:30px;
		height:20px;
		width:20px;
	}
	
	.light-content #ball i.arrow-icon {
		border: solid #fff;
		border-width: 3px 3px 0px 0px;
	}
	
	#ball i.arrow-icon:after {
		content:"";
		position:absolute;
		width:30px;
		height:3px;
		top:8px;
	  	left:-8px;
		background-color:#fff;
		transform: rotate(-45deg);
  		-webkit-transform: rotate(-45deg);
	}
	
	.light-content #ball i.arrow-icon:after {
		background-color:#fff;
	}
	
	
/*--------------------------------------------------
	03. Page Preloader
---------------------------------------------------*/	
	
	.disable-ajaxload .preloader-wrap {
		display:none;
	}
	
	.preloader-wrap {
		width: 100%;
		height:100%;
		position: fixed;
		top: 0; 
		bottom: 0;
		background: #000;
		z-index : 1800;
		text-align:center;
	}
	
	.preloader-wrap .outer {
		padding: 0px;
		box-sizing:border-box;
	}
	
	.preloader-wrap .inner {
		vertical-align:middle;
		box-sizing:border-box;
	}
	
	.percentage-wrapper {
		position:relative;
		display:block;
		text-align:center;
		float:left;
		width:auto;
		overflow:hidden;
		z-index:10;
		mix-blend-mode: difference;
	}
	
	.percentage {
		opacity: 1;
		font-size: 16px;
		line-height: 60px;
		width:50px;
		font-weight: 500;
		color:#fff;		
		display: table;
		margin: 0 auto;
		margin-top:1px;
		opacity:0;
		text-align:center;
	}
	
	.percentage::after {
		content: "%";
	}
	
	.percentage-intro {
		height: 20px;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		color:rgba(255,255,255,0.6);
		position: absolute;
		bottom: 60px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
	
	.trackbar {
		width: 100%;
		height: 100%;
		clip-path: inset(45% 40% 45% 40%);
		margin: 0 auto;
		display: flex;
		position: absolute;
		top:0;
		left:0;
		padding: 0 80px;
		box-sizing:border-box;
		opacity: 1;
		background: rgba(255,255,255,0.05);
	}
	
	.loadbar {
		width: 0;
		height: 100%;
		background: #fff;
		position: absolute;
		top: 0px;
		left: 0;
		right: auto;
		overflow: hidden;
		z-index:0;
	}
	
	.loadbar-inner {
		width: 100%;
		height: 100%;
		background: #000;
		position: absolute;
		top: 0px;
		left: auto;
		right: auto;
		overflow: hidden;
		z-index:20;
		clip-path: circle(0%);
	}
	
	.hold-progress-bar {
		width: 0%;
		height: 0px;
		background: rgba(0,0,0,1);
		position: absolute;
		top: 0px;
		left: 0;
		right:0;
		overflow: hidden;
	}
	
	.preloader-intro {
		position:relative;
		display:block;
		color:#fff;
		overflow:hidden;
		padding-left:0vw;		
		float:right;
		z-index:10;
		mix-blend-mode: difference;		
		opacity:0;
	}
	
	.uppercase-titles .preloader-intro {
		text-transform:uppercase;
	}
	
	.preloader-intro span {
		position: relative;
		display:block;
		font-weight:500;
		position: relative;
		font-size: 16px;
		line-height: 60px;
	}
	
/*--------------------------------------------------
	04. Header Elements
---------------------------------------------------*/	
	
		
	header {
		width:100%;
		height:120px;
		left:0;
		top:0;
		background-color:transparent;
		position:fixed;
		box-sizing:border-box;
		z-index:1000;
		pointer-events:none;
		-webkit-transition: background 0.4s ease-in-out 0.3s;
		transition: background 0s ease-in-out 0s;
	}
	
	#header-container {
		box-sizing: border-box;
		height: inherit;
		padding: 20px 80px;
		margin: 0 auto;
		position: relative;
		width: 100%;
		z-index: 20;
		opacity: 0;
	}
	
	.header-visible #header-container{
		opacity:1;
	}
	
	#logo {
		position: relative;
		display: table;
		pointer-events: initial;
		z-index: 10;
		top: 15px;
		float: left;
	}
	
	#logo a {
		display:block;
		-webkit-transition: transform 0.5s ease-in-out 0.1s;
		transition: transform 0.5s ease-in-out 0.1s;	
	}
	
	#logo a.disable, .open #logo a {
		pointer-events: none;
	}
	
	#logo img {
		display: block;
		height: 50px;
		width: auto;
		max-width:none;
		-webkit-transition: opacity 0.15s ease-in-out 0s;
		transition: opacity 0.15s ease-in-out 0s;
	}
	
	#logo img.black-logo {
		opacity:1;
	}
	
	.light-content #logo img.black-logo {
		opacity:0;
	}
	
	#logo img.white-logo {
		position:absolute;
		top:0;
		left:0;
		opacity:0;
	}
	
	.light-content #logo img.white-logo {
		opacity:1;
	}
	
	.white-header #logo img.black-logo {
		opacity:0;
	}
	
	.light-content .white-header #logo img.black-logo {
		opacity:1;
	}
	
	.light-content .white-header.over-white-section #logo img.black-logo, .show-loader1 .light-content .white-header #logo img.black-logo {
		opacity:0;
	}
	
	.white-header #logo img.white-logo {
		opacity:1;
	}
	
	.light-content .white-header #logo img.white-logo {
		opacity:0;
	}
	
	.light-content .white-header.over-white-section #logo img.white-logo, .show-loader1 .light-content .white-header #logo img.white-logo {
		opacity:1;
	}
	
	.menu-open.light-content .slide-in #logo img.white-logo {
		opacity:1;
	}
	
	nav {
		pointer-events: none;
	}
	
	nav.open {
		pointer-events: initial;
	}
	
	.dark-content-header #logo img.black-logo {
		opacity:1!important;
	}
	
	.dark-content-header #logo img.white-logo {
		opacity:0!important;
	}
	
	.light-content-header #logo img.black-logo {
		opacity:0!important;
	}
	
	.light-content-header #logo img.white-logo {
		opacity:1!important;
	}
	
	@media all and (min-width: 1025px) {
		
		.classic-menu nav {
			position: relative;
			width: auto;
			top: 0px;
			-webkit-transition: all 0.2s ease-in-out 0.5s;
			transition: all 0.2s ease-in-out 0.5s;
			display:block;
			float:right;
			height: 60px;
			margin: 10px 0;
			background-color:transparent!important;
			opacity: 1 !important;
		}
		
		.classic-menu .flexnav {
			display: block;
			float: right;
			position: relative;
			width: auto;
			max-height: 60px;
		}
		
		.classic-menu .flexnav li ul {   
			min-width: 180px;
		}	
		
		.classic-menu .flexnav .touch-button {
			background: transparent none repeat scroll 0 0;
		}	
		
		.flexnav .touch-button .navicon {
			display:none;
		}
		
		.classic-menu .flexnav li {    
			background: transparent none repeat scroll 0 0;
			padding:0 30px;
			padding-bottom:20px;
			padding-top:20px;
		}
		
		.classic-menu .menu-timeline {
			opacity:1!important;
			-webkit-transition: translateY(0px)!important;
			transform: translateY(0px)!important;
			pointer-events:initial;
		}
		
		.classic-menu .flexnav li:last-child {
			padding-right:0px;
		}
		
		.classic-menu .before-span {
			opacity:1!important;
			-webkit-transition: translateY(0px)!important;
			transform: translateY(0px)!important;
			
		}
		
		.classic-menu .flexnav li a {    
			background: transparent none repeat scroll 0 0;
			padding: 0;
			position:relative;
			border-left: medium none;
			color:#222;		
			font-size:14px;
			font-weight:500;
			line-height:24px;
			display:block;
			overflow:hidden;			
		}
		
		.light-content .classic-menu .flexnav li a { 
			color:#fff;		
		}
		
		.light-content  .classic-menu.white-header .flexnav li a { 
			color:#222;		
		}
		
		.classic-menu.white-header .flexnav li a { 
			color:#fff;		
		}
		
		.flexnav:hover li a {
			color: rgba(0,0,0,0.4);
		}
		
		.light-content .classic-menu .flexnav:hover li a {
			color: rgba(255,255,255,0.4);
		}
		
		.classic-menu.white-header .flexnav:hover li a {
			color: rgba(255,255,255,0.4);
		}
		
		.light-content  .classic-menu.white-header .flexnav:hover li a {
			color: rgba(0,0,0,0.4);	
		}
		
		.classic-menu .flexnav li:hover a {
			color:#222;
		}
		
		.light-content .classic-menu .flexnav li:hover a {
			color:#fff;
		}
		
		.classic-menu.white-header .flexnav li:hover a {
			color:#fff;
		}
		
		.light-content .classic-menu.white-header .flexnav li:hover a {
			color:#222;
		}
	
		.classic-menu .flexnav li a span {
			position: relative;
			display: block;
			-webkit-transition: -webkit-transform 0.2s;
			transition: transform 0.2s;
			transform-origin: 100% 0%;
		}
		
		.classic-menu .flexnav li a span::before {
			position: absolute;
			top: 100%;
			width:100%;
			left:0;
			content: attr(data-hover);
		}
		
		.classic-menu .flexnav li:hover a span {
			-webkit-transform: translateY(-100%);
			transform: translateY(-100%);
			transform-origin: 0% 0%;
		}
		
		.classic-menu .flexnav li a span:nth-child(2), .classic-menu .flexnav li a span:nth-child(3) {
			display:none;
		}
		
		.classic-menu .flexnav li ul li a { 
			padding:0 20px 20px;
			background-color:transparent;
			font-size:14px;
			font-weight:400;
			text-transform:none;
			color:#fff!important;
			opacity:0.6;
			-webkit-transition: all 0.05s ease-in-out;
			-moz-transition: all 0.05s ease-in-out;
			-o-transition: all 0.05s ease-in-out;
			-ms-transition: all 0.05s ease-in-out;
			transition: all 0.05s ease-in-out;
		}
		
		.classic-menu .flexnav li ul li a.link::before {
			display:none;
		}
		
		.classic-menu .flexnav li ul li a.active, .classic-menu .flexnav li ul li a:hover {
			opacity:1;
		}
		
		.classic-menu .flexnav li > ul li {
			margin-left:0;
			padding:0;
			opacity: 1 !important;
			transform: none !important;
		}
		
		.classic-menu .flexnav li ul {
			-webkit-transform: translate3d(0px, 30px, 0px);
			transform: translate3d(0px, 30px, 0px);
			display:block!important;
			opacity:0!important;
			height:inherit!important;
			overflow:visible!important;
			visibility:hidden;
			left:15px;
			top:60px;
			padding-top:20px;
			background: #000;		
			border-radius:3px;
			-webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s ease-in-out!important;
			transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s ease-in-out!important;
		}
		
		.classic-menu .flexnav li:last-child ul {
			left:auto;
			right:-10px;
		}
		
		.classic-menu .flexnav li ul ul {
			left:5px!important;

		}
		
		.classic-menu .flexnav li ul:after {
			display: block;
			content: '';
			position: absolute;
			top: -6px;
			left: 20px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 9px;
			border-color: transparent transparent #000;
		}
		
		.classic-menu .flexnav li:last-child ul:after {
			left: auto;
			right: 20px;
		}
		
		.classic-menu .flexnav ul li ul:after {
			display: block;
			content: '';
			position: absolute;
			top: 15px;
			left: -10px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 9px;
			border-color: transparent transparent #000;
			-webkit-transform: rotate(-90deg);
			transform: rotate(-90deg);
		}
		
		.classic-menu .flexnav li ul li > ul, .classic-menu .flexnav li ul.open ul.open {
			top: -5px;
			z-index:10;
		}
		
		.classic-menu .flexnav li:last-child ul li > ul, .classic-menu .flexnav li:last-child ul.open ul.open {
			margin-left: -100%;
			left:-5px!important;
			right:auto;
			top: 0;
		}
		
		.classic-menu .flexnav li:last-child ul li ul::after {
			right: -10px;
			-webkit-transform: rotate(90deg);
			transform: rotate(90deg);
		}
		
		.classic-menu .flexnav ul li ul li a {
			background: #000;
		}
		
		.classic-menu .flexnav ul li ul li ul li a {
			background: #000;
		}
		
		.classic-menu .flexnav li ul.flexnav-show {
			-webkit-transform: translate3d(0px, 0px, 0px);
			transform: translate3d(0px, 0px, 0px);
			opacity:1!important;
			visibility:visible;
			-webkit-transition: opacity 0.2s ease-in-out 0.1s, visibility 0.2s ease-in-out, transform 0.2s ease-in-out 0.1s;
			transition: opacity 0.2s ease-in-out 0.1s, visibility 0.2s ease-in-out, transform 0.2s ease-in-out 0.1s;	
		}
		
		.classic-menu .flexnav .touch-button {
			display:none;
		}
				
		
		/*-- Full Screen Menu --*/	
				
		.fullscreen-menu .nav-height {
			overflow-y: scroll;
			position: relative;
			height: 100%;
			padding: 40px 0;
			padding-right: 30px;
			width: calc(100% + 30px);
			box-sizing: border-box;
		}
		
		.fullscreen-menu nav {
			height: 100vh;
			position: fixed;
			top:0;
			left:0;
			width: 100%;
			box-sizing: border-box;
			visibility: visible;
			pointer-events:none;
			opacity:0;
			padding:20px 0;
		}
		
		.fullscreen-menu nav.open {
			visibility:visible;
			pointer-events:initial;
		}

		
		.fullscreen-menu .flexnav {
			max-height: 2000px;
			-webkit-transition: all 0.2s ease-in 0.6s;
			transition: all 0.2s ease-in 0.6s;
			display: table;
			width: auto;
			padding:0 20px;
			box-sizing:border-box;
			margin: 0 auto;
			float: none;
		}
		
		.fullscreen-menu .flexnav.flexnav-show {
			-webkit-transition: all .3s ease-out 0.2s;
			transition: all .3s ease-out 0.2s;	
		}
		
		.fullscreen-menu .flexnav li {
			text-align:center;
			padding:0;
			box-sizing:border-box;
			float:none;			
		}
		
		.fullscreen-menu .menu-timeline {
			opacity:0;
		}
		
		.fullscreen-menu .flexnav li ul li .touch-button {
			height:32px;
		}
		
		.fullscreen-menu .flexnav:hover li ul li a.active, .fullscreen-menu .flexnav:hover li ul li:hover > a {
            opacity:1;
        }
		
		.fullscreen-menu .flexnav .touch-button .navicon {
			display:none;
		}
		
		.fullscreen-menu .flexnav li.link {
			border-bottom: none;	
		}
		
		.fullscreen-menu .flexnav > li > a {
			font-family: 'Six Caps', sans-serif;
			font-weight: 500;
			font-size: calc(1rem + 8vw);
  			line-height: calc(1rem + 7.5vw);
			padding: 0 50px;
			-webkit-transition: all .1s ease-in-out 0s;
			transition: all .1s ease-in-out 0s;
			text-transform:lowercase;
		}
		
		.fullscreen-menu .flexnav .touch-button {
			width: 100%;
			display: block;
			height:calc(1rem + 7.5vw);
		}
		
		.fullscreen-menu .flexnav li a {
			color:#222;
		}
		
		.fullscreen-menu.invert-header .flexnav li a {
			color: #fff;
		}
		
		.fullscreen-menu .flexnav:hover li a, .fullscreen-menu .flexnav.hover li a {
			opacity:0.3;
		}
		
		.fullscreen-menu .flexnav li:hover a, .fullscreen-menu .flexnav li a.active{
			opacity:1;
		}
		
		.fullscreen-menu .flexnav:hover li ul li a {
			opacity:0.3;
		}
		
		.fullscreen-menu .flexnav:hover li ul li a.active, .fullscreen-menu .flexnav:hover li ul li:hover > a {
			opacity:1;
		}
		
		.fullscreen-menu .flexnav a.link::before {
			display:none;
		}
		
		.fullscreen-menu .flexnav li ul {
			margin-top:10px;
			margin-bottom:10px;
			position:relative;
			left:0;			
		}
		
		.fullscreen-menu .flexnav li ul:after {
			display:none;
		}
		
		.fullscreen-menu .flexnav li ul li a {
			padding: 3px 0;
			font-size: 16px;
			line-height: 26px;
			font-weight:500;
			border-top: none;
			text-transform:none;
		}
		
		.uppercase-text .fullscreen-menu .flexnav li ul li a {
			text-transform:uppercase;
			line-height:20px;				
		}
		
		.fullscreen-menu .flexnav ul li ul li a {
			background:transparent;
		}
		
		.fullscreen-menu .flexnav li ul li > ul, .fullscreen-menu .flexnav li ul.open ul.open {
			margin-left: 0;
			top: 0;
		}
		
		.fullscreen-menu .menu-timeline {
			opacity:1;
		}
		
		.fullscreen-menu .menu-timeline .before-span {
			opacity:0;
			display:block;
			-webkit-transform: translateY(120px);
			transform: translateY(120px);
		}
		
		.fullscreen-menu .flexnav li, .fullscreen-menu .flexnav li a  {
			background-color:transparent;
			border:0;
		}
		
		.fullscreen-menu .flexnav li a span {
			position: relative;
			display: block;
			-webkit-transition: -webkit-transform 0.2s;
			transition: transform 0.2s;
			transform-origin: 100% 0%;
			overflow:hidden;
		}
		
		.fullscreen-menu .open .flexnav li a span {
			overflow:visible;
		}
		
		.fullscreen-menu .flexnav li a span::before {
			position: absolute;
			top: 100%;
			width:100%;
			left:0;
			content: attr(data-hover);
		}
		
		.fullscreen-menu .flexnav li:hover a span {
			-webkit-transform: translateY(-100%);
			transform: translateY(-100%);
			transform-origin: 0% 0%;
		}
		
		.fullscreen-menu .flexnav li.hover a  span {
			-webkit-transform: translateY(-100%)!important;
			transform: translateY(-100%)!important;
			transform-origin: 0% 0%;
		}
	
	}

	nav, nav.open {
		background:transparent;
	}

	#burger-wrapper {
		width: 40px;
		height: 40px;
		float: right;
		top: 0px;
		right: 0;
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		pointer-events: initial;
	}
	
	.classic-menu .button-wrap.right.menu {
		display:none;
	}

	.burger-dots #menu-burger {
		top:0px;
		width: 25px;
		height: 25px;
		position: relative;
		margin: 0 auto;		
		z-index:2;
		pointer-events: none;
	}
	
	.menu-overlay #menu-burger {
		display:block;
	}

	.burger-dots #menu-burger span {
	  display: block;
	  position: absolute;
	  height: 5px;
	  width: 5px;
	  border-radius:5px;
	  background-color: #000;
	  opacity: 1;
	  top:8px;
	  left:0;
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	  -webkit-transition: background-color  0.05s ease-in-out,  transform  0.2s ease-in-out,  height  0.2s ease-in-out, width  0.2s ease-in-out, top 0.2s ease-in-out, left  0.2s ease-in-out;
	  transition: background-color  0.05s ease-in-out,  transform  0.2s ease-in-out,  height  0.2s ease-in-out, width  0.2s ease-in-out, top 0.2s ease-in-out, left  0.2s ease-in-out;
	}
	
	#burger-wrapper .touch-button {
		display:none;
	}

	.burger-dots #menu-burger span:nth-child(1) {
	  	left: 0px;
	}
	
	.burger-dots #menu-burger span:nth-child(2){
	  	left: 10px;
	}
	
	.burger-dots #menu-burger span:nth-child(3){
	  	left: 20px;
	}
	
	.button-wrap.right.menu.burger-dots .icon-wrap:hover #menu-burger span:nth-child(1) {
	  	left: 10px;
	}
	
	.button-wrap.right.menu.burger-dots .icon-wrap:hover #menu-burger span:nth-child(3) {
	  	left: 10px;
	}
	
	.button-wrap.right.menu.burger-dots .icon-wrap:hover #menu-burger.open span:nth-child(1) {
	  	left: 3px;
	}
	
	.button-wrap.right.menu.burger-dots .icon-wrap:hover #menu-burger.open span:nth-child(3) {
	  	left: 3px;
	}
	
	.burger-dots #menu-burger.open span:nth-child(1) {
	  -webkit-transform: rotate(45deg);
	  transform: rotate(45deg);
	  top:12px;
	  height: 2px;
	  width:20px;
	  left: 3px;
	}
	
	.burger-dots #menu-burger.open span:nth-child(2) {
	   -webkit-transform: scale(0);
	  transform: scale(0);
	}
	
	.burger-dots #menu-burger.open span:nth-child(3) {
	  -webkit-transform: rotate(-45deg);
	  transform: rotate(-45deg);
	  top:12px;
	  height: 2px;
	  width:20px;
	  left: 3px;
	}
	
	.button-wrap.right.burger-lines {
		right: -30px;
	}
	
	.burger-lines #menu-burger {
		width: 16px;
		height: 22px;
		position: relative;
		margin: 0 auto;		
		z-index:2;
		pointer-events: none;
	}
	
	.burger-lines #menu-burger span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		opacity: 1;
		right: 0;
		background-color:#000;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: background-color  0.05s ease-in-out,  transform  0.2s ease-in-out,  top  0.2s ease-in-out;
		transition: background-color  0.05s ease-in-out,  transform  0.2s ease-in-out,  top  0.2s ease-in-out;
	}
	
	.burger-lines #menu-burger span:nth-child(1) {
	  top: 5px;
	}
	
	.burger-lines #menu-burger span:nth-child(2){
	  top: 13px;
	}
	
	.burger-lines #menu-burger span:nth-child(3){
		display:none;
	}
	
	.burger-lines #menu-burger.open span:nth-child(1) {
	  -webkit-transform: rotate(45deg);
	  -moz-transform: rotate(45deg);
	  -o-transform: rotate(45deg);
	  transform: rotate(45deg);
	  top:10px;
	}
	
	.burger-lines #menu-burger.open span:nth-child(2) {
	  -webkit-transform: rotate(-45deg);
	  -moz-transform: rotate(-45deg);
	  -o-transform: rotate(-45deg);
	  transform: rotate(-45deg);
	  top:10px;
	}
	
	.light-content #menu-burger span {
		background-color:#fff;
	}
	
	.white-header #menu-burger span {
		background-color:#fff;
	}
	
	.light-content .white-header #menu-burger span {
		background-color:#000;
	}
	
	.light-content .white-header.over-white-section #menu-burger span, .show-loader1 .light-content .white-header #menu-burger span {
		background-color:#fff;
	}
	
	.dark-content-header #menu-burger span {
		background-color:#000!important;
	}
	
	.light-content-header #menu-burger span {
		background-color:#fff!important;
	}
	
	.dark-content-header .button-text {
		color:#000!important;
	}
	
	.light-content-header .button-text {
		color:#fff!important;
	}
	
	
/*--------------------------------------------------
	05. Main Content
---------------------------------------------------*/		
	
	#content-scroll {
		z-index:1;
		position:relative;
	}
	
	.smooth-scroll #content-scroll {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index:1;		
		overflow:hidden; 
	}
	
	.scroll-content {
		cursor:default!important;
	}
	
	.scale-up .scroll-content {
		cursor:grabbing!important;
	}
	
	.scrollbar-track {
		background: transparent!important;
	}
	
	
/*--------------------------------------------------
	06. Footer Elements
---------------------------------------------------*/		
	
	footer {
		position:relative;
		width:100%;
		height:120px;
		z-index:900;
		box-sizing:border-box;
		text-align:center;
		bottom:0;
		left:0;
		overflow:hidden;
		pointer-events:none;
	}
	
	footer.fixed {
		position:fixed;
	}
	
	footer.showcase-footer{
		position:fixed;
	}
	
	#footer-container {
		padding: 0 80px;
		margin: 20px auto;
		height: 80px;
		opacity: 0;
		position: absolute;
		width: 100%;
		box-sizing: border-box;
		bottom: 0;
		left: 0;
	}
	
	.footer-middle {
		position: absolute;
		margin: 0 auto;
		width: 100%;
		height: inherit;
		display: table;
		left: 0;
		pointer-events: none;;
	}
	
	.copyright {
		position: relative;
		top: 25px;
		width: auto;
		height: 30px;
		overflow: hidden;
		font-size: 14px;
		line-height: 30px;
		font-weight: 500;
		color: #000;
		pointer-events: initial;
		display: table;
		width: auto;
		padding: 0px 20px 0px 20px;
		max-width: 820px;
		box-sizing:border-box;
		text-align:center;
		margin: 0 auto;	
	}
	
	.light-content .copyright {
		color:#fff;
	}
	
	.socials-wrap {
		position:relative;
		float: right;
		color: #000;
		width: 240px;
		height: 160px;
		box-sizing: border-box;
		pointer-events:initial;	
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.light-content .socials-wrap {
		color: #fff;
	}
	
	footer .socials-wrap {
		margin-top:0px;
	}
	
	.socials-wrap:hover {
		transform: translateY(-40px);
		-webkit-transform: translateY(-40px);
	}
	
	.socials-text {
		float:right;
		font-size:14px;
		font-weight: 500;		
		line-height:40px;
		transform:translateY(20px);
	}
	
	.socials-wrap:hover .socials-text {
		transform: translateY(15px) translateX(5px);
		-webkit-transform: translateY(15px) translateX(5px);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;		
	}
	
	.socials-icon {
		float:right;
		width:30px;
		height:40px;
		font-size:14px;
		line-height:40px;
		text-align:right;
		margin-left:10px;
		transform:scale(1) translateY(20px);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.socials-wrap:hover .socials-icon {
		transform:scale(0) translateY(40px);
		width:0;
		margin:0;
		overflow:hidden;
		
	}
	
	.socials-icon:after, .socials-text:after {
	  content: "";
	  clear: both;
	  display: table;
	}
	
	.socials {
		height: auto;
		line-height: 30px;
		position: relative;
		width: auto;
		margin:0 auto;
		margin-top: 10px;
		text-align: center;
		bottom: 0;
		display: table;
		transform: translateX(15px);
		-webkit-transform: translateX(15px);
	}
	
	.socials-wrap .socials {
		display: inline-block;
		width: 100%;
		text-align: right;
	}
	
	.socials li {
		margin-right: 0px;
		margin-left: 10px;
		list-style: none;
		color: #999;
		font-size: 15px;
		margin-bottom: 0;
		line-height: 40px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		width: 40px;
		float: right;
		z-index: 2;
		-webkit-transition: background 0.4s ease-in-out 0s;
		transition: background 0.4s ease-in-out 0s;
	}
	
	.socials-wrap .socials li {
		opacity:0;
		transform: translateY(20px);
		-webkit-transform: translateY(20px);
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}
	
	.socials-wrap:hover .socials li:nth-child(5) {
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.05s;
		opacity:1;
		transform: translateY(10px);
		-webkit-transform: translateY(10px);		
	}
	
	.socials-wrap:hover .socials li:nth-child(4) {
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.1s;
		opacity:1;
		transform: translateY(10px);
		-webkit-transform: translateY(10px);	
	}
	
	.socials-wrap:hover .socials li:nth-child(3) {
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.15s;
		opacity:1;
		transform: translateY(10px);
		-webkit-transform: translateY(10px);		
	}
	
	.socials-wrap:hover .socials li:nth-child(2) {
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s;
		opacity:1;
		transform: translateY(10px);
		-webkit-transform: translateY(10px);	
	}
	
	.socials-wrap:hover .socials li:nth-child(1) {
		transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
		opacity:1;
		transform: translateY(10px);
		-webkit-transform: translateY(10px);		
	}
		
	.socials li:last-child {
		margin-left: 0px;
	}
	
	.socials li a {
		color: #000;		
		font-size:14px;
		font-weight: 500;
		display: block;
		height: 40px;
		width: 40px;		
		line-height:40px;
		text-align:center;
		-webkit-transition: opacity 0.2s ease-in-out;
		transition: opacity 0.2s ease-in-out;
	}
	
	.socials:hover li  a {
		opacity:0.3;
	}
	
	.socials li a:hover {
		color: #000;
		opacity:1;
	}
	
	.light-content .socials li a {
		color: #fff;	
	}
	
	.light-content .socials:hover li  a {
		opacity:0.3;
	}
	
	.light-content .socials li a:hover {
		color: #fff;
		opacity:1;
	}
	
	.button-wrap {
		position: relative;
		cursor: pointer;
		top: 0px;
		pointer-events: initial;
		width: auto;
		display: table;
	}
	
	.button-wrap:after {
		clear: both;
		content: " ";
		display: block;
		height: 0;
		visibility: hidden;
	}
	
	.button-wrap.left {
		float:left;
		left:-32px;	
	}
	
	.button-wrap.right {
		float:right;
		right:-26px;	
	}
	
	#main .button-wrap.left {
		float:none;
		left:-24px;		
	}
	
	#main .button-wrap.button-link.left {
		float:none;
		left:-20px;		
	}
	
	#main .button-wrap.right {
		float:none;
		right:auto;			
	}
	
	#main .button-wrap.button-link.right {
		right:-2px;		
	}
	
	.icon-wrap {
		width: 80px;
		height: 80px;
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
		float:left;
	}
	
	#main .icon-wrap {
		width: 60px;
		height: 60px;
	}
	
	.button-wrap.left .icon-wrap {
		float:left;
	}
	
	.button-wrap.right .icon-wrap {
		float:right;
	}
	
	#main  .text-align-right .button-wrap.right {
		float:right;
	}
	
	.button-icon {
		height: 40px;
		width: 40px;
		color: #000;
		text-align: center;
		line-height: 40px;
		font-size: 18px;
	}
	
	.button-icon i.fa-solid.fa-sort {
		font-size: 14px;
		line-height: 38px;
	}
	
	.light-content .button-icon, .dark-section .button-icon {
		color:#fff;
	}
	
	.light-content .light-section .button-icon {
		color:#000;
	}
	
	.white-header .button-icon {
		color:#fff;		
	}
	
	.light-content .white-header .button-icon {
		color:#000;		
	}
	
	.button-icon i {
		height:40px;
		line-height:40px;
		width:40px;
		text-align:center;
	}
	
	#main .button-icon i {
		line-height:40px;
	}
	
	#main .button-icon i.fa.fa-arrow-right {
		font-size:14px;
	}
	
	.button-text {
		font-weight: 500;
		font-size: 14px;
		line-height:30px;
		margin:25px 0;
		overflow: hidden;
		width:auto;
		padding:0 10px;
		color:#000;
		-webkit-transition: color 0.05s ease-in-out;
		transition: color 0.05s ease-in-out;
	}
	
	#main #hero-footer .button-text span::after {
		content:none;
	}
	
	#main .button-text {
		font-size: 18px;
		line-height:30px;
		font-weight: 500;
		margin:15px 0;
		transition: transform .2s ease-out;
	}
	
	#main #hero #hero-footer .button-text {
		font-size: 14px;
		line-height:30px;
	}
	
	#main .large-btn .button-text {
		font-size: 20px!important;
		font-weight:500;
		margin:15px 0;
		transition: transform .2s ease-out;
	}
	
	.light-content .button-text, .dark-section .button-text {
		color:#fff;
	}
	
	.light-content .light-section .button-text {
		color:#000;
	}
	
	.white-header .button-text {
		color:#fff;		
	}
	
	.light-content .white-header .button-text {
		color:#000;		
	}
	
	.light-content .white-header.over-white-section .button-text, .show-loader1 .light-content .white-header .button-text {
		color:#fff;		
	}
	
	.button-wrap.left a {
		text-align:left;
		float: left;
	}
	
	.button-wrap.right a {
		text-align:right;
		float: right;
	}
	
	.button-wrap.right .button-text {
		padding-left:0px;
	}
	
	.button-text span {
		position: relative;
		display: inline-block;
		-webkit-transition: -webkit-transform 0.2s;
		transition: transform 0.2s;
		width:auto;
		text-align:center;
		transform-origin: 100% 0%;
	}
	
	.button-link .button-text, #main .button-link .button-text {
		overflow:visible;
		transition: padding 0.3s ease-out;
	}
	
	.button-link.left .button-text {
		padding-left:0px;
	}
	
	.button-link.left:hover .button-text {
		padding-left:10px;
	}
	
	.button-link .button-text span::before {
		content: '';
		position: absolute;
		bottom: 1px;
		left: 0;
		width: 100%;
		height: 1px;
		background: transparent;
		transform: scaleX(1);
		transform-origin: right;
		transition: transform 0.3s ease-out;
	}
	
	.button-link.left .button-text span::before {
		transform-origin: left;
	}
	
	.light-content .button-link .button-text span::before, .dark-section .button-link .button-text span::before {
		background: transparent;
	}
	
	.light-content .light-section .button-link .button-text span::before {
		background: transparent;
	}
  
    .button-wrap.button-link:hover .button-text span {
		transform: translateY(0);
		-webkit-transform: translateY(0);
	}
	
	.button-link:hover .button-text span::before {
		transform: scaleX(0);
		-webkit-transform: scaleX(0);
		transform-origin: left;
	}
	
	.button-link.left:hover .button-text span::before {
		transform-origin: right;
	}
	
	.button-link .button-icon i {
		background-color:#000;
		border:1px solid rgba(0,0,0,1);
		color:transparent;
		font-size: 14px;
		border-radius:100%;
		transform: scale(0.2);
		box-sizing:border-box;
		transition: all .3s ease-out;
		text-indent:-100px;
		overflow:hidden;
		opacity:1;
	}
	
	.button-link:hover .button-icon i {
		background-color:#000;
		color:#fff;
		border:1px solid rgba(0,0,0,1);
		transform: scale(1.2);
		text-indent:0px;
		opacity:1;
	}
	
	.light-content .button-link .button-icon i, .dark-section .button-link .button-icon i {
		background-color:#fff;
		border:1px solid rgba(255,255,255,1);
	}
	
	.light-content .button-link:hover .button-icon i, .dark-section .button-link:hover .button-icon i {
		background-color:#fff;
		color:#000;
		border:1px solid rgba(255,255,255,1);
		opacity:1;
	}
	
	.light-content .light-section .button-link .button-icon i {
		border:1px solid rgba(0,0,0,1);
	}
	
	.light-content .light-section .button-link:hover .button-icon i {
		background-color:#000;
		color:#fff;
		border:1px solid rgba(0,0,0,1);
		opacity:1;
	}
	
	
	.button-link.right .button-icon i {
		transform: scale(0.2) translateX(-60px);
		-webkit-transform: scale(0.2) translateX(-60px);
	}
	
	.button-link.right:hover .button-icon i {
		transform: scale(1.2) translateX(0px);
		-webkit-transform: scale(1.2) translateX(0px);
	}
	
	.button-wrap.left .button-text span {
		text-align:left;
	}
	
	.button-wrap.right .button-text span {
		text-align:right;
	}
	
	.button-text span::before {
		position: absolute;
		width:auto;
		top: 100%;
		left:0;
		content: attr(data-hover);
	}
	
	.header-middle .button-text span::before {
		content: attr(data-on);
	}
	
	.button-wrap:hover .button-text span {
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
		transform-origin: 0% 0%;
	}
	
	.link-text {
		font-weight: 500;
		font-size: 14px;
		line-height:30px;
		margin:0;
		overflow: hidden;
		display: table;
		width:auto;
		padding:0;
		color:#000;
		-webkit-transition: color 0.05s ease-in-out;
		transition: color 0.05s ease-in-out;
	}
	
	footer .link-text {
		float:left;
		opacity:0;
		margin-top: 25px;
	}
	
	p .link-text {
		display:inline-table;
		font-size:16px;
	}
	
	.light-content .link-text, .dark-section .link-text {
		color:#fff;
	}
	
	.light-content .light-section .link-text {
		color:#000;
	}
	
	.link-text span {
		position: relative;
		display: inline-block;
		-webkit-transition: -webkit-transform 0.2s;
		transition: transform 0.2s;
		width:auto;
		text-align:center;
		transform-origin: 100% 0%;
		pointer-events:initial;
		cursor:pointer;
	}
	
	.link-text span::before {
		position: absolute;
		width:auto;
		top: 100%;
		left:0;
		content: attr(data-hover);
	}
	
	.link-text:hover span {
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
		transform-origin: 0% 0%;
	}
	
	nav, nav.open {
		background:transparent;
	}
	
/*--------------------------------------------------
	07. Responsive
---------------------------------------------------*/			

			
@media only screen and (max-width: 1466px) {
	
	.row_padding_all {
		padding-left: 60px;
		padding-right: 60px;
	}
	
	.row_padding_left {
		padding-left:60px;
	}
	
	.row_padding_right {
		padding-right:60px;
	}
	
	.percentage-wrapper {
		width:10vw;
	}
	
	.percentage-intro {
  		bottom: 50px;
	}
	
	header {
    	height: 100px;
	}
	
	#header-container {
		padding: 10px 60px;
	}
	
	.flexnav li {
    	padding: 20px;
	}
	
	footer {
    	height: 100px;
	}
	
	#footer-container {
    	padding: 0 60px;
		margin: 10px auto;
	}
	
	.socials-wrap:hover .socials-text {
		transform: translateY(20px) translateX(5px);
		-webkit-transform: translateY(20px) translateX(5px);		
	}
	
}


@media only screen and (max-width: 1024px) {
	
	.parallax-wrap {
		transform:none!important;
	}
	
	.parallax-element {
		transform:none!important;
	}
	
	.has-parallax figcaption {
		display:none;
	}
	
	.destroy {
		display:none;
	}
	
	.row_padding_left {
		padding-left: 0px;
	}
	
	.row_padding_right {
		padding-right: 0px;
	}
	
	.full.row_padding_left {
		padding-left: 40px;
	}
	
	.full.row_padding_right {
		padding-right: 40px;
	}
	
	.row_padding_all {
		padding-top: 4vw;
		padding-bottom: 4vw;
		padding-left: 0px;
		padding-right: 0px;
	}
	
	.full.row_padding_all {
		padding-top: 4vw;
		padding-bottom: 4vw;
		padding-left: 40px;
		padding-right: 40px;
	}
	
	.big-title {
		font-size: calc(1rem + 12vw);
		line-height: calc(1rem + 11.5vw);
	}	
	
	#magic-cursor {
		display:none;
	}
	
	.percentage-wrapper {
		width:12vw;
	}
	
	.percentage-intro {
  		bottom: 40px;
	}

	
	#header-container {
		padding: 10px 40px;
	}
	
	nav {
		height: 100vh;
		width: 100%;
		position: fixed;
		left: 0;
    	top: 0;		
		box-sizing: border-box;
		visibility: visible;
		pointer-events:none;
		opacity:0;
		padding:20px 0;	
	}
	
	nav.open {
		visibility:visible;
		pointer-events:initial;
	}
	
	.nav-height {
		position: relative;
		height: 100%;
		padding: 40px 0;
		width: 100%;
		box-sizing: border-box;
		overflow-y: auto;
	}
	
	.flexnav {
		padding: 0 20px;
    	max-height: 2000px;
		display: table;
		width: auto;
		margin: 0 auto;
		text-align:center;
		box-sizing:border-box;
		background: transparent;
		-webkit-transition: all 0.2s ease-in 0.6s;
		transition: all 0.2s ease-in 0.6s;
	}
	
	.flexnav.flexnav-show {
		-webkit-transition: all .3s ease-out 0.2s;
		transition: all .3s ease-out 0.2s;	
	}
	
	.flexnav.opacity {
		opacity: 1;
	}
	
	.flexnav li {
		padding:0;
		-webkit-transition: color .15s ease-out 0s;
		transition: color .15s ease-out 0s;	
	}
	
	.flexnav:hover li  {
		color:rgba(255,255,255,0.4)
	}
	
	.flexnav li:hover {
		color:rgba(255,255,255,1)
	}
	
	.flexnav li a, .flexnav li ul li a {
    	background: transparent;
	}
	
	.flexnav li a {
		font-weight:500;
		font-size: 9vw;
		line-height: 10vw;
		padding:0;
		border:none;
		-webkit-transition: all .1s ease-in-out 0s;
		transition: all .1s ease-in-out 0s;
	}
	
	.flexnav > li > a {
		font-size: calc(1rem + 12vw);
		line-height: calc(1rem + 11.5vw);
		font-family: 'Six Caps', sans-serif;
		text-transform: lowercase;		
	}
	
	.flexnav > li > .touch-button {
		width: 100%;		
		height:calc(1rem + 11.5vw);
		display: block;
	}
	
	.flexnav li a {
		color:#000;
	}
	
	.invert-header .flexnav li a {
		color: #fff;
	}
	
	.flexnav:hover li a {
		opacity:0.3;
	}
	
	.flexnav li:hover a, .flexnav li a.active{
		opacity:1;
	}
	
	.flexnav .touch-button {
		width: 100%;
		display: block;
		height:10vw;
	}
	
	.flexnav li ul {
		margin-bottom:15px;
	}
	
	.flexnav li ul li a {
		padding: 3px 0;
		font-weight: 500;
		text-transform:none;
		font-size: 16px;
		line-height: 26px;
		border-top: none;
		opacity:0.4;
		-webkit-text-stroke:none;
	}
	
	.uppercase-text .flexnav li ul li a {
		text-transform:uppercase;		
	}
	
	.flexnav li ul li:hover a, .flexnav li ul li a.active{
		opacity:1;
	}
	
	.flexnav:hover li ul li a {
		opacity:0.3;
	}
	
	.flexnav:hover li ul li a.active, .flexnav:hover li ul li:hover > a {
		opacity:1;
	}
	
	.flexnav ul li ul li a {
		background: transparent;
	}
	
	.flexnav li ul li ul li a {
		padding: 10px 40px;	
	}
	
	.flexnav li ul li .touch-button {
		height:30px;
	}
	
	.flexnav .touch-button .navicon {
		display:none;
	}
	
	#menu-burger span.touch-button {
		display:none;
	}
	
	.classic-menu .button-wrap.right.menu {
		display: block;
	}
	
	#footer-container {
    	padding: 0 40px;	
	}
	
	.copyright {
		text-align:center;
	}
	
	.socials-wrap {
		width: 240px;
		height: 40px;
		float:right;
		margin:0 auto;
		right:-10px;
		transform: translateX(0px) translateY(20px)!important;
		-webkit-transform: translateX(0px) translateY(20px)!important;
	}
	
	.socials-wrap:hover {
		transform: translateY(20px);
		-webkit-transform: translateY(20px);
	}
	
	.socials-icon, .socials-text {
		display:none;
	}
	
	.socials-wrap .socials {
		margin-top: 3px;
		text-align: right;
		transform: translateX(0px);
		-webkit-transform: translateX(0px);	
	}
	
	.socials-wrap .socials li {
		opacity: 1;
		transform: translateY(0px);
		-webkit-transform: translateY(0px);
	}
	
	.socials-wrap:hover .socials li {
		opacity: 1;
		transform: translateY(0px)!important;
		-webkit-transform: translateY(0px)!important;
	}
	
	.socials li {
		margin-right: 10px;
		line-height: 30px;
		height: 30px;
		width: 30px;
		margin: 0 5px auto;
		display: inline-block;
		float: none;
	}
	
	.socials li a {
		height: 30px;
		width: 30px;
		line-height: 30px;
	}
	
}


@media only screen and (max-width: 767px) {
	
	figcaption {
		background-color: rgba(0,0,0,0.3);
		bottom: 0px;
		font-size: 10px;
		padding: 5px 10px;
		right: 0px;
		border-radius: 0;
	}
	
	.text-aligh-right {
		text-align:left;
	}
	
	.one_half {
    	width: 100%!important;
	}
	
	.one_half {
    	padding-right: 0!important;
	}
	
	.one_half.last {
    	padding-left: 0!important;
	}

	.one_third {
		width: 100%!important;
	}	

	.one_fourth {
		width: 100%!important;
	}	

	.one_fifth {
		width: 100%!important;
	}	

	.one_sixth {
		width: 100%!important;
	}	

	.two_fifth {
		width: 100%!important;
	}	

	.two_fourth {
		width: 100%!important;
	}		

	.two_third {
		width: 100%!important;
	}	

	.three_fifth {
		width: 100%!important;
	}	

	.three_fourth {
		width: 100%!important;
	}	

	.four_fifth {
		width: 100%!important;
	}	

	.five_sixth {
		width: 100%!important;
	}	

	.one_half, .one_third, .two_third, .three_fourth, .one_fourth, .two_fourth, .one_fifth, .two_fifth, .three_fifth, .four_fifth, .one_sixth, .five_sixth {
		margin-bottom: 30px!important;
		margin-right: 0%!important;
	}
	
	.row_padding_top {
		padding-top:10vw;
	}
	
	.row_padding_bottom {
		padding-bottom:10vw;
	}
	
	.row_padding_left {
		padding-left: 0px;
	}
	
	.row_padding_right {
		padding-right: 0px;
	}
	
	.full.row_padding_left {
		padding-left: 30px;
	}
	
	.full.row_padding_right {
		padding-right: 30px;
	}
	
	.row_padding_all {
		padding-top: 4vw;
		padding-bottom: 4vw;
		padding-left: 0px;
		padding-right: 0px;
	}
	
	.full.row_padding_all {
		padding-top: 4vw;
		padding-bottom: 4vw;
		padding-left: 30px;
		padding-right: 30px;
	}
	
	.clip-effects {
		overflow: hidden;
		clip-path: inset(2% 5% round 30px);
	}
	
	hr {
		height: 20px;
	}
	
	.big-title {
		font-size: calc(1rem + 13vw);
		line-height: calc(1rem + 12.5vw);
	}
	
	.bigger {
		font-size: 20px;
		line-height: 30px;
	}
	
	.percentage-wrapper {
		width:16vw;
	}
	
	.percentage-intro {
  		bottom: 30px;
	}
	
	header {
		height:80px;
	}
	
	#header-container {
    	padding: 0;
		margin: 0 auto;
	}
	
	#logo {
		left: 30px;
	}
	
	.button-wrap.right.menu {
		right: 4px;
	}
	
	.button-wrap.right.menu.burger-lines {
		right: 0px;
	}
	
	header .button-text {
		display: none;
	}
	
	.flexnav > li > a {
		font-size: calc(1rem + 13vw);
		line-height: calc(1rem + 12.5vw);
	}
	
	.flexnav > li > .touch-button {
		height:calc(1rem + 12.5vw);
	}
	
	footer {
		height: 80px;
	}
	
	.footer-button-wrap {
    	bottom: 80px;
	}
	
	#footer-container {
		height: 80px;
		padding: 0 30px;
		margin:0;
	}
	
	#footer-container #backtotop.button-wrap.left {
		float: none;
		top:5px;
		left: 0;
		right: 0;
		position: absolute;
		display:block;
	}
	
	#backtotop.button-wrap.left .button-text {
		text-align: left;
		float: left;
		display: none;
	}
	
	#backtotop.button-wrap.left .icon-wrap {
		float: none;
		position: absolute;
		right: auto;
		left: 0;
		margin: auto;
		top: 0px;
		opacity:0.4;
		-webkit-transition: opacity 0.2s ease-out 0s;
		transition: opacity 0.2s ease-out 0s;
	}
	
	#backtotop.button-wrap.left:hover .icon-wrap {
		opacity:1;
	}
	
	.footer-middle {
		max-width: 1180px;
		height:20px;
		position: relative;
		margin: 0 auto;
		left: 0;
		width: 100%;
		top: 0px;
		margin-bottom: 15px;
	}
	
	.copyright {
		margin: 0 auto;
		left: auto;
		right: auto;
		top: 0;
		height: 20px;
		line-height: 20px;
	}
	
	footer.showcase-footer .arrows-wrap {
		right: auto;
		left: -30px;
		float: left;
		margin: auto;
	}
	
	.socials-wrap {
		width: auto;
		float: none;
		display: table;
		left: auto;
		right: auto;
		top: 0;
		margin: 0 auto;		
		height: 20px;
		line-height: 20px;
		transform: translateX(0px) translateY(0px)!important;
		-webkit-transform: translateX(0px) translateY(0px)!important;
	}
	
	.socials-wrap .socials {
		text-align: center;
		margin: 0 auto;
		height: 20px;
		line-height: 20px;
		display: table;
		width: auto;
	}
	
	.socials li, .socials li a {
		height: 20px;
		line-height: 20px;
		margin: 0 auto;
	}
	
}


@media only screen and (max-width: 479px) {	
	
	.one_half, .one_third, .two_third, .three_fourth, .one_fourth, .two_fourth, .one_fifth, .two_fifth, .three_fifth, .four_fifth, .one_sixth, .five_sixth {
		margin-bottom: 20px!important;
		margin-right: 0%!important;
	}
	
	.row_padding_top {
		padding-top:12vw;
	}
	
	.row_padding_bottom {
		padding-bottom:12vw;
	}
		
	.row_padding_left {
		padding-left: 0px;
	}
	
	.row_padding_right {
		padding-right: 0px;
	}
	
	.full.row_padding_left {
		padding-left: 20px;
	}
	
	.full.row_padding_right {
		padding-right: 20px;
	}
	
	.row_padding_all {
		padding-top: 4vw;
		padding-bottom: 4vw;
		padding-left: 0px;
		padding-right: 0px;
	}
	
	.full.row_padding_all {
		padding-top: 4vw;
		padding-bottom: 4vw;
		padding-left: 20px;
		padding-right: 20px;
	}
	
	hr {
		height: 10px;
	}
	
	.big-title {
		font-size: calc(1rem + 14vw);
		line-height: calc(1rem + 13.5vw);
	}
	
	.percentage-wrapper {
		width:20vw;
	}
	
	#logo {
		left: 20px;
	}
	
	.button-wrap.right.menu {
		right: -6px;
	}
	
	.button-wrap.right.menu.burger-lines {
		right: -10px;
	}
	
	.flexnav > li > a {
		font-size: calc(1rem + 14vw);
		line-height: calc(1rem + 13.5vw);
	}
	
	.flexnav > li > .touch-button {
		height:calc(1rem + 13.5vw);
	}
	
	#footer-container {
		padding: 0px;
		margin:0;
	}
	
	footer.showcase-footer #footer-container {
		padding: 0px 20px;
	}
	
	.arrows-wrap {
		left: -10px;
		width: 80px;
	}
	
	.prev-wrap, .next-wrap {
    	width: 40px;
	}
	
	footer.showcase-footer .arrows-wrap {
		right: 0;
		left: 0;
		float: none;
		margin: auto;
	}
	
	footer.showcase-footer .arrows-wrap {
		width: 100%;
	}
	
	footer.showcase-footer .prev-wrap, footer.showcase-footer .next-wrap {
    	width: 60px;
	}
	
	footer.showcase-footer .footer-middle {
		top: 0px;
	}
	
	footer.showcase-footer .showcase-subtitles-wrap {
		left: 0;
	}
	
	footer.showcase-footer .socials-wrap {
		display:none;
	}
	
	.button-text {
		display:none;
	}
	
	#main-page-content .button-text, #hero .button-text {
		display:block;
	}
}

@media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : landscape) {
    
	#rotate-device {
		display:block;
	}
}