
/*--------------------------------------------------
	Pinned Section
---------------------------------------------------*/
	
.pinned-section {
  position: relative;
  display: table!important;
  width: 100%;
  margin: 0 auto;
  max-width:1160px;
}

.full .pinned-section, .full_elementor .pinned-section {
  max-width:100%;
}

.pinned-section .pin-spacer {
  width:50%!important;
}

.pinned-element {
  position:relative;
  display:block;
  height:auto;
  float:left;
  width:50%;
  margin:0;
  box-sizing:border-box;
}

.pinned-element.left {
  padding-right:30px;
}

.pinned-element.right {
  padding-left:30px;
}

.scrolling-element {
  position:relative;
  float:left;
  width:50%;
  margin:0;
  box-sizing:border-box;
}

.scrolling-element.left {
  padding-right:30px;
}

.scrolling-element.right {
  padding-left:30px;
}

.scrolling-element img {
  display:block;
  height: auto;
  width:100%;
}

/*--------------------------------------------------
Content Text Effects
---------------------------------------------------*/	

.title-moving-outer {
  margin-left: calc(50% - 50vw)!important;
  margin-right: calc(50% - 50vw)!important;
  max-width: 1000%!important;
  width: 100vw!important;
}

.title-moving-forward, .title-moving-backward {
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  padding:0 6vw;
  box-sizing:border-box;
}

.marquee-text-wrapper {
    white-space: nowrap;
  margin-left: calc(50% - 50vw)!important;
  margin-right: calc(50% - 50vw)!important;
  max-width: 1000%!important;
  width: 100vw!important;
}

.marquee-text {
  font-weight: 500;
  display: inline-block;
  color:#000;
}

.marquee-text:before {
  content: '.';
  color:transparent;
}

.light-content .marquee-text, .dark-section .rolling-text {
  color:#fff;
}

.light-content .light-section .marquee-text {
  color:#000;
}


/*--------------------------------------------------
Hover Reveal Image
---------------------------------------------------*/	

.hover-reveal {
  position: fixed;
  width: 20vw;
  height: 30vw;
  pointer-events: none;
  opacity: 0;		
  left:auto;
  right:auto;
  margin:auto;
}

.content-row > .hover-reveal {
  z-index:-1;
}

.rounded-borders .hover-reveal {
  border-radius:8px;
  overflow:hidden;
}

.hover-reveal__inner, .hover-reveal__img {
  width: 100%;
  height: 100%;
  position: absolute;
}

.hover-reveal__img {
  background-size:cover;
  background-position:center center;
  background-repeat:no-repeat;
  width:100%;
  height: 100%;
  position:relative;
  display:block;
}	


/*--------------------------------------------------
Carousel
---------------------------------------------------*/

.clapat-slider-wrapper.content-slider  {
  width:100%;
  height: auto;
  position:relative;	
}

.content-slider .clapat-slider-viewport {
  display: flex;
  position: relative;
  margin: 0 auto;
}

.content-slider.looped-carousel .clapat-slider-viewport {
  max-width:1280px;
}

.content-slider.small-looped-carousel .clapat-slider-viewport {
  max-width:600px;
}

.content-slider .clapat-slider {
  padding: 0 0 0 0;
  height: auto;
  position:relative;	
}

.content-slider .clapat-slide {
  width: 100%;
}

.content-slider.looped-carousel .clapat-slide {
  padding:0 60px;
}

.content-slider.small-looped-carousel .clapat-slide {
  padding:0 20px;
}

.rounded-borders .content-slider {
  border-radius:8px;
  overflow:hidden;
}

.rounded-borders .content-slider.looped-carousel img, .rounded-borders .content-slider.small-looped-carousel img {
  border-radius:8px;
}

.content-slider.looped-carousel .clapat-button-prev, .content-slider.looped-carousel .clapat-button-next {
  display:none;
}

.disable-cursor .content-slider.looped-carousel .clapat-button-prev, .disable-cursor .content-slider.looped-carousel .clapat-button-next {
  display:block;
}

.content-slider.small-looped-carousel .clapat-button-prev, .content-slider.small-looped-carousel .clapat-button-next {
  display:none;
}

.disable-cursor .content-slider.small-looped-carousel .clapat-button-prev, .disable-cursor .content-slider.small-looped-carousel .clapat-button-next {
  display:block;
}

.content-slider .clapat-pagination {
  bottom:20px;
}

.content-slider .slider-button-prev {
  position:absolute;
  top:0;
  left:0;
  margin:0;
  width:50%;
  height:100%;
  z-index:10;
  cursor:pointer;	
}

.content-slider .slider-button-next {
  position:absolute;
  top:0;
  right:0;
  margin:0;
  width:50%;
  height:100%;
  z-index:10;
  cursor:pointer;		
}

.content-slider .clapat-pagination {
  opacity:1;
}

.content-slider .clapat-pagination-bullet .parallax-wrap {
  width: 40px;
  height: 40px;
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  pointer-events: initial;
}

.content-slider .clapat-pagination-bullet .parallax-element {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 50px;
}

.clapat-pagination-bullet-active .path {
  display: inline-block !important;
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation: dash ease-in 3s;
  animation-iteration-count: unset;
}

.clapat-pagination-bullet .path, .light-content.light-content-slider .clapat-pagination-bullet .path {
  stroke:#000;
}

.clapat-pagination-bullet .solid-fill, .light-content.light-content-slider .clapat-pagination-bullet .solid-fill {
  fill:#000;
}

.light-content .clapat-pagination-bullet .path, .dark-section .clapat-pagination-bullet .path, .light-content-slider .clapat-pagination-bullet .path {
  stroke:#fff;
}

.light-content .light-section .clapat-pagination-bullet .path {
  stroke:#000;
}

 .light-content .clapat-pagination-bullet .solid-fill, .dark-section .clapat-pagination-bullet .solid-fill, .light-content-slider .clapat-pagination-bullet .solid-fill {
   fill:#fff;
 }
 
 .light-content .light-section .clapat-pagination-bullet .solid-fill, .light-content .dark-section .dark-cursor .clapat-pagination-bullet .solid-fill {
   fill:#000;
 }
 
.light-cursor .clapat-pagination-bullet .path {
  stroke:#fff!important;
}

.dark-cursor .clapat-pagination-bullet .path {
  stroke:#000!important;
}

.light-cursor .clapat-pagination-bullet .solid-fill {
  fill:#fff!important;
}

.dark-cursor .clapat-pagination-bullet .solid-fill {
  fill:#000!important;
}

.path {
  display: none;
}

@keyframes dash {
  from {
  stroke-dashoffset: 1000;
  }

  to {
  stroke-dashoffset: 0;
  }
}

.disable-cursor .full .looped-carousel .clapat-controls, .disable-cursor .full_elementor .looped-carousel .clapat-controls {
  position: absolute;
  width: calc(100% - 60px);
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.disable-cursor .full .small-looped-carousel .clapat-controls, .disable-cursor .full_elementor .small-looped-carousel .clapat-controls {
  position: absolute;
  width: calc(100% - 60px);
  max-width:540px;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.disable-cursor .slider-button-prev {
  position: absolute;
  top: calc(50% - 30px);
  left: 0px;
  margin: 0;
  width: 60px;
  height: 60px;
  z-index: 10;
  cursor: pointer;
  opacity:0.7;
  -webkit-transition: opacity 0.15s ease-out;
  transition: opacity 0.15s ease-out;
}

.disable-cursor .slider-button-prev:hover {
  opacity:1;
}

.disable-cursor .slider-button-prev::after {
  font: var(--fa-font-solid);
  content: "\f104";
  font-size: 24px;
  width:60px;
  height:60px;
  line-height:60px;
  text-align:center;
  position: absolute;
  left: 0;
  top:0;
  color:#fff;
}

.disable-cursor .light-cursor .slider-button-prev::after, .disable-cursor .light-cursor .slider-button-next::after {
  color:#fff;
}

.disable-cursor .dark-cursor .slider-button-prev::after, .disable-cursor .dark-cursor .slider-button-next::after {
  color:#000;
}

.disable-cursor .slider-button-next {
  position: absolute;
  top: calc(50% - 30px);
  left:auto;
  right: 0px;
  margin: 0;
  width: 60px;
  height: 60px;
  z-index: 10;
  cursor: pointer;
  opacity:0.7;
  -webkit-transition: opacity 0.15s ease-out;
  transition: opacity 0.15s ease-out;
}

.disable-cursor .slider-button-next:hover {
  opacity:1;
}

.disable-cursor .slider-button-next::after {
  font: var(--fa-font-solid);
  content: "\f105";
  font-size: 24px;
  width:60px;
  height:60px;
  line-height:60px;
  text-align:center;
  position: absolute;
  left: 0;
  top:0;
  color:#fff;
}


/*--------------------------------------------------
Light Box
---------------------------------------------------*/	

.image-link, .video-link {
  display: block;
  width: auto;
  
}

.rounded-borders .image-link img, .rounded-borders .video-link img {
  border-radius:8px;
}

.disable-cursor .image-link::after {
  font: var(--fa-font-solid);
  content: "\f002";
  font-size: 18px;
  width:60px;
  height:60px;
  line-height:60px;
  text-align:center;
  position: absolute;
  right:10px;
  top:10px;
  color:#fff;
  opacity:0;
  -webkit-transition: opacity 0.15s ease-out;
  transition: opacity 0.15s ease-out;
}

.disable-cursor .image-link:hover::after {
  opacity:1;
}

.disable-cursor .video-link::after {
  font: var(--fa-font-solid);
  content: "\f04b";
  font-size: 18px;
  width:60px;
  height:60px;
  line-height:60px;
  text-align:center;
  position: absolute;
  right:10px;
  top:10px;
  color:#fff;
  opacity:0;
  -webkit-transition: opacity 0.15s ease-out;
  transition: opacity 0.15s ease-out;
}

.disable-cursor .video-link:hover::after {
  opacity:1;
}


/*--------------------------------------------------
Video Player
---------------------------------------------------*/	

.hero-video-wrapper {
  position: absolute;
    box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding:0;
  overflow:hidden!important;
  opacity:0;		
}

.item .hero-video-wrapper, .clapat-item .hero-video-wrapper, .hover-reveal__img .hero-video-wrapper, .clapat-slider .hero-video-wrapper, .open-thumbnail .hero-video-wrapper, .carousel-zoom-wrapper .hero-video-wrapper, .carousel-thumbs-wrapper .hero-video-wrapper, .gallery-zoom-wrapper .hero-video-wrapper, .gallery-thumbs-wrapper .hero-video-wrapper, .overlapping-image .hero-video-wrapper {
  position: absolute;
  box-sizing: border-box;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: auto;
  background-size: cover;
  padding: 0;
  overflow: hidden !important;
  opacity: 1;
  top: 0;
  z-index: 120;
  pointer-events: none;
}

.content-video-wrapper {
  position: absolute;
    box-sizing: border-box;
  width: 100%;
  height: 100%;
  left:0;
  top:0;
  padding:0;
  overflow:hidden!important;
  z-index: 1;
}

.hero-video-wrapper video.bgvid, .content-video-wrapper video.bgvid {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.video-wrapper {
  position: relative;
    box-sizing: border-box;
  min-width: 100%;
  max-height: 100vh;
  width: 100%;
  height: auto;
  background-size: cover;
  padding:0;
  overflow:hidden!important;
  opacity:1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.rounded-borders .video-wrapper {
  border-radius: 8px;
}

.video-cover {
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  z-index:100;
  cursor:pointer;
  background-size:cover;
  background-position:center center;
  background-repeat:no-repeat;
  -webkit-transition: opacity 0.3s ease-out 0.05s, height 0s ease-out 0s;
  transition: opacity 0.3s ease-out 0.05s, height 0s ease-out 0s;
}

.video-cover.hidden {
  opacity:0;
  height: calc(100% - 50px);
  -webkit-transition: opacity 0.3s ease-out, height 0s ease-out 0.3s;
  transition: opacity 0.3s ease-out, height 0s ease-out 0.3s;
}

video.bgvid { 
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-position: center; 
  object-fit: cover;
  z-index: -100;
}

.video-wrapper video.bgvid {
  position: relative;
}

.control {
  bottom: 25px;
  color: #ccc;
  display: none;
  left: 0;
  position: absolute;
  width: calc(100% - 40px);
  z-index: 5;
  background-color: #eee;
  padding: 0;
  box-sizing: border-box;
  right: 0;
  margin: 0 auto;
}

.full .control, .full_elementor .control {
  bottom: 10px;
  width: calc(100% - 160px);
}

.btmControl{
  clear:both;
}

.control .sound{
  width: 30px;
  height: 50px;
  float:left;
  cursor:pointer;
  text-align:center;
  line-height:50px;
  color:#fff;
  opacity:0.4;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.control .sound:hover {
  opacity:1;
}

.control .sound i {
  line-height:50px;
}

.control .sound.muted i:first-child {
  display:none;
}

.control .sound.muted i:last-child {
  display:block;
}

.control .sound i:last-child {
  display:none;
}

.control .btnFS{
  width: 50px;
  height: 50px;
  float:left;
  cursor:pointer;
  text-align:center;
  line-height:50px;
  color:#fff;
  opacity:0.4;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.control .btnFS:hover{
  opacity:1;
}

.progress-bar {
  background: rgba(255,255,255,0.5);
  box-sizing: border-box;
  float: left;
  height: auto;
  width: calc(100% - 123px);
  cursor: pointer;
  bottom: 23px;
  position: absolute;
  left: 23px;
  opacity:0;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.video-wrapper:hover .progress-bar {
  opacity:1;
}

.progress {
  height: 2px;
  max-width: 100%;
  position: relative;
  width: 100%;
}

.progress span {
  height:100%;
  position:absolute;
  top:0;
  left:0;
  display:block;
}

.timeBar{
  z-index:10;
  width:0;
  background: #fff;
}

.bufferBar{
  z-index:5;
  width:0;
  background: rgba(255,255,255,0.2);
}

.video-btns {
  position: absolute;
  right: 3px;
  bottom: 0px;
  opacity:0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.video-wrapper:hover .video-btns {
  opacity:1;
}

.volume{
  position:relative;
  cursor:pointer;
  width:70px;
  height:10px;
  float:right;
  margin-top:10px;
  margin-right:10px;
}

.volumeBar{
  display:block;
  height:100%;
  position:absolute;
  top:0;
  left:0;
}

/*--------------------------------------------------
Buttons
---------------------------------------------------*/	

.button-box {
  line-height:16px;
  cursor:pointer;
  border:none;
  box-sizing:border-box;
  text-align:center;
  display:inline-block;
  position:relative;
}

.text-align-center .button-box, .text-align-center.button-box {
  margin-left:10px;
  margin-right:10px;
  text-align:center;
}

.text-align-center.button-box {
  display: flex;
  margin:auto
}

.button-content .submit-box {
  color:#fff;
}

.clapat-button-wrap {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  left:0;
  right:0;
  min-width:100px;
  height:60px;		
}

.clapat-button-wrap.circle {
  min-width:104px;
  height:104px;	
}

.text-align-center .clapat-button-wrap {
  margin:0 auto;
}

.clapat-button {		
  opacity:1;		
}

.button-border {
  height: 50px;
  width: auto;
  border: solid 2px #000;
  background-color:#000;
  border-radius: 5px;
  cursor:pointer;
  font-size: 16px;
  font-weight: 500;
  line-height:50px;
  overflow: hidden;
  color:#000;
  text-align:center;
  -webkit-transition : border 0.2s ease-out;
  transition : border 0.2s ease-out;
  box-sizing:initial !important;
}

.light-content .button-border, .dark-section .button-border {
  border: solid 2px #fff;
  background-color:#fff;
}

.light-content .light-section .button-border {
  border: solid 2px #000;
  background-color:#000;
}

.light-content .button-border a, .dark-section .button-border a {
  color:#000;
}

.light-content .light-section .button-border a {
  color:#fff;
}

.button-border.rounded {
  border-radius: 30px;
}

.button-border.outline {
  border: solid 2px #000;
  color:#000;
  background-color:transparent;
}

.light-content .button-border.outline, .dark-section .button-border.outline {
  border: solid 2px #fff;
  color:#fff;		
  background-color:transparent;
}

.light-content .light-section .button-border.outline {
  border: solid 2px #000;		
  background-color:transparent;
}

.clapat-button a {
  position: relative;
  display: block;
  height: 100%;
}

.light-content .button-border.outline a, .dark-section .button-border.outline a {
  color:#fff;
}

.light-content .light-section .button-border.outline a {
  color:#000;
}

.dark-content .light-section .button-border a {
  color:#fff;
}

.dark-content .light-section .button-border.outline a {
  color:#000;
}

.circle .button-border {
  border-radius: 100px;
  height: 100px;
  width: 100px;
}

.button-border span {
  position: relative;
  display: inline-block;
  -webkit-transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  width: auto;
  text-align: center;
  transform-origin: 100% 0%;
  padding: 0 40px;
}

.button-border span::before {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  content: attr(data-hover);
  text-align: center;
}

.clapat-button:hover .button-border span {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  transform-origin: 0% 0%;
}

.clapat-button-wrap.circle:hover .button-border {
  border: solid 2px transparent;	
}

.clapat-button-wrap.circle .button-border input, .clapat-button-wrap.circle .button-border a {
  height: 100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
  padding: 0;
  font-size: 12px;
  cursor:pointer;	
}



/*--------------------------------------------------
Appearing Items
---------------------------------------------------*/	

.has-animation {
  opacity:0;
  -webkit-transition:translateY(30px);
  transform: translateY(30px);
}

.has-animation.has-scale {
  opacity: 0;
  -webkit-transition:translateY(0px) scale(1.05);
  transform: translateY(0px) scale(1.05);
  transform-origin: 50% 0;
  will-change:transform;
}

.has-animation.has-scale-vertical {
  opacity: 0;
  -webkit-transition:translateY(0px) scaleY(1.1);
  transform: translateY(0px) scaleY(1.1);
  transform-origin: 50% 0;
  will-change:transform;
}

.has-animation.has-cover {
  opacity: 1;
  -webkit-transition:translateY(0px);
  transform: translateY(0px);
  overflow:hidden;
}

.has-animation.has-cover::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color:inherit;
  position:absolute;
  top:0;
  right:0;
  z-index:10;
  -webkit-transition: all .6s cubic-bezier(0.54, 0.49, 0.2, 1.01);
  -o-transition: all .6s cubic-bezier(0.54, 0.49, 0.2, 1.01);
  transition: all .6s cubic-bezier(0.54, 0.49, 0.2, 1.01);	
}

.has-animation.has-cover.animated::after {
  width:0;
}

.has-animation.has-cover img {
  opacity: 1;
  -webkit-transition:scale(1.05);
  transform:scale(1.05);
  -webkit-transition: all .6s cubic-bezier(0.54, 0.49, 0.2, 1.01);
  -o-transition: all .6s cubic-bezier(0.54, 0.49, 0.2, 1.01);
  transition: all .6s cubic-bezier(0.54, 0.49, 0.2, 1.01);	
}

.has-animation.has-cover.animated img {
  opacity: 1;
  -webkit-transition:scale(1);
  transform:scale(1);
}

.has-mask {
  margin-bottom:10px;
}

.has-mask span {
  display:inline-table;
  overflow:hidden;
}

.has-mask span > span {
  display: inline-table;
  margin:0!important;
  line-height:inherit;
  height:inherit;
  opacity:0;
  -webkit-transform:translateY(60px);
  transform: translateY(60px);
}

h1.big-title.has-mask span, h1.big-title.has-mask span > span {
  line-height:inherit;
  height:inherit;
  margin-right:12px;
}

h1.has-mask span, h1.has-mask span > span {
  line-height:inherit;
  height:inherit;
  margin-right:12px;
}

h2.has-mask span, h2.has-mask span > span {
  line-height:inherit;
  height:inherit;
  margin-right:10px;
}

h3.has-mask span, h3.has-mask span > span {
  line-height:inherit;
  height:inherit;
  margin-right:8px;
}	

h4.has-mask span, h4.has-mask span > span {
  line-height:inherit;
  height:inherit;
  margin-right:7px;
}

h5.has-mask span, h5.has-mask span > span {
  line-height:inherit;
  height:inherit;
  margin-right:6px;
}

h6.has-mask span, h6.has-mask span > span {
  line-height:inherit;
  height:inherit;
  margin-right:5px;
}

.has-mask-fill > span {
  position: relative;
  display: inline;
  -webkit-text-fill-color: rgba(0,0,0,0.1);
  -webkit-background-clip: text;
  background-repeat: no-repeat;
  background-image: linear-gradient(#000, #000);
    background-size: 0% 100%;
}

.has-mask-fill.block-title > span {
  display: block;
}

.light-content .has-mask-fill > span, .dark-section .has-mask-fill > span {
  -webkit-text-fill-color: rgba(255,255,255,0.1);
  background-image: linear-gradient(#fff, #fff);;
}

.light-content .light-section .has-mask-fill > span {
  -webkit-text-fill-color: rgba(0,0,0,0.1);
  -webkit-background-clip: text;
  background-repeat: no-repeat;
  background-image: linear-gradient(#000, #000);
}

.has-indent {
  text-indent:8vw;
}

.has-span-indent {
  margin-right: 30px;
  display: inline;
  vertical-align: top;
  line-height: inherit;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-transform: initial;
  opacity:0.4;
}

.has-hover-image {
  cursor:pointer;
  display:inline-block;
  position:relative;
}

span.has-hover-image::before {
  content: '';
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 2px;
  background: rgba(0,0,0,1);
  transform: scaleX(1);
  transform-origin: left;
  transition: transform .2s ease-out;
}

.light-content span.has-hover-image::before, .dark-section span.has-hover-image::before {
  background: rgba(255,255,255,1);
}

.light-content .light-section span.has-hover-image::before {
  background: rgba(0,0,0,1);
}

  span.has-hover-image:hover::before {
  transform: scaleX(0);
  transform-origin: right;
}

span.has-hover-image .hover-reveal {
  left:0;
  width: 400px;
  height: 250px;
  overflow:hidden;
}

span.has-hover-image.vertical .hover-reveal {
  width: 300px;
  height: 400px;
}

.has-opacity span {
  opacity:0.2;
}

.number-counter {
  position: relative;
  display:table;
}

.number-counter::after {
  content: attr(data-symbol);
  line-height: inherit;
  top: 0;
  position: absolute;
  left: calc(100% + 5px);
}


/*--------------------------------------------------
Underline Link
---------------------------------------------------*/			

a.link {
  display:inline-block;
  position:relative;
}

.light-content a.link {
  color:#fff;
}

.light-content .light-section a.link {
  color:#000;
}

a.link::before {
  content: '';
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: 2px;
  background: rgba(0,0,0,1);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform .2s ease-out;
}

h1 a.link::before {
  bottom: 0px;
  height: 3px;
}

.light-content a.link::before, .dark-section a.link::before {
  background: rgba(255,255,255,1);
}

.light-content .light-section a.link::before {
  background: rgba(0,0,0,1);
}

  a.link:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}


/*--------------------------------------------------
Icon Boxes
---------------------------------------------------*/

.box-icon-wrapper {
  display: table;
  position: relative;
  box-sizing: border-box;
  height: auto;
  width:100%;
}

.box-icon {
  color: #000;
  position: relative;
  margin-top: 0px;
  margin-bottom: 10px;
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.text-align-center .box-icon {
  margin:auto;
}

.inline-boxes .box-icon {
  position:absolute;
}

.box-icon i {
  position:relative;
  margin-bottom:0px;
  font-size:24px;
}

.light-content .box-icon {
  color:#fff;
}

.light-content .light-section .box-icon {
  color:#000;
}

.box-icon:after {
  content: "";
  clear: both;
  display: table;
}

.box-icon-content {
  position:relative;
  width:100%;
  box-sizing:border-box;
}

.inline-boxes .box-icon-content {
  padding-left:45px;
}

.box-icon h5 {
  margin-bottom:5px;
}


/*--------------------------------------------------
Parallax Image
---------------------------------------------------*/	

.parallax-image-content {
  position: relative;
  margin:0 auto;
  z-index: 10;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.parallax-image-content .outer {
  width: 100%;
  margin: 0 auto;
  padding-top:20px;
  padding-bottom:20px;
  box-sizing: border-box;
}

.parallax-image-content img {
  width:100%;
  max-width:100%;
  height:auto;
  display:block;
}

.rounded-borders .parallax-image-content img {
    border-radius: 8px;
}

/*--------------------------------------------------
Clients Table
---------------------------------------------------*/	

.clients-table {
  position:relative;
  margin:0;
  display:flex;
  justify-content:space-between;
  flex-wrap: wrap;
  /* background-image:url("../images/clients-middle.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.light-content .clients-table, .dark-section .clients-table {
  /* background-image:url("../images/clients-middle-white.png"); */
}

.light-content .light-section .clients-table {
  /* background-image:url("../images/clients-middle.png"); */
}

.clients-table::before {
    content: "";
  position: absolute;
  width: 100%;
  height: 20px;
  top: 0;
  left: 0;
  /* background-image:url("../images/clients-top.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.light-content .clients-table::before, .dark-section .clients-table::before {
  /* background-image:url("../images/clients-top-white.png"); */
}

.light-content .light-section .clients-table::before {
  /* background-image:url("../images/clients-top.png"); */
}

.clients-table::after {
    content: "";
  position: absolute;
  width: 100%;
  height: 20px;
  bottom: 0;
  left: 0;
  /* background-image:url("../images/clients-bottom.png"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.light-content .clients-table::after, .dark-section .clients-table::after {
  /* background-image:url("../images/clients-bottom-white.png"); */
}

.light-content .light-section .clients-table::after {
  /* background-image:url("../images/clients-bottom.png"); */
}

.clients-table li {
  position:relative;
  display: block;
  width:calc(100% / 4 - 40px);
  height: auto;
  aspect-ratio: 13/10;
  box-sizing: border-box;
  text-align: center;
  margin: 20px;
  overflow:hidden;
  padding:30px;
}

.clients-table li img {
  width:100%;
  height: 100%;
  margin:0 auto;
  opacity:0.6;
  position:relative;
  display:block;
  object-position: center; 
  object-fit: cover;
  transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
}

.clients-table li:hover img {
  opacity:1;
}

.clients-table.no-borders li {
  border:none;
}

.overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 00;
  left: 0;
  color: #FFF;
  background-color: rgba(0,0,0,0.1);
  z-index: 10;
  transform:scale(0.85);
  -webkit-transform:scale(0.85);
  opacity:0;
  transition: all 0.4s ease 0s;
  -webkit-transition: all 0.4s ease 0s;
  pointer-events:none;
}

.light-content .clients-table .overlay, .dark-section .clients-table .overlay {
  background-color: rgba(255,255,255,0.05);
}

.light-content .light-section .clients-table .overlay {
  background-color: rgba(0,0,0,0.1);
}

.clients-table li:hover .overlay {
  transform:scale(0.95);
  -webkit-transform:scale(0.95);
  opacity:1;
}




/*--------------------------------------------------
Accordion
---------------------------------------------------*/

.accordion {
  padding:20px 0;
  padding-top:0;
  margin:0;
}

.accordion.bigger-acc, .light-content .accordion.bigger-acc, .dark-section .accordion.bigger-acc, .light-content .light-section .accordion.bigger-acc {
  border-top: none;
  border-bottom: none;
  padding:0px 0;
}

.accordion dt {
  position:relative;
  display:flex;
  align-items: center;
  cursor: pointer;
  padding: 0px;
  margin-bottom:0px;
  margin-top: 20px;
  padding-top: 20px;
  font-size:20px;
  line-height:30px;
  font-weight:500;	
  -webkit-transition:all 0.2s ease 0s;
    transition:all 0.2s ease 0s;
  z-index:10;
  border-top: 1px solid rgba(0,0,0,0.1);
}

.accordion.bigger-acc dt {
  font-size: calc(1rem + 1vw);
  line-height: calc(1rem + 1.5vw);
  margin-top: 40px;
  padding-top: 40px;
}

.uppercase-titles .accordion.bigger-acc dt {
  text-transform:uppercase;
}

.light-content .accordion dt, .dark-section .accordion dt {
  border-top: 1px solid rgba(255,255,255,0.2);
}

.light-content .light-section .accordion dt {
  border-top: 1px solid rgba(0,0,0,0.1);
}

.accordion dt:first-child {
  margin-top: 0px;
  border-top: none;
}

.accordion dt span {
  width: calc(100% - 30px);
  position: relative;
  overflow: hidden;
  display: block;
  padding: 0px;
  color:#000;		
  font-family:inherit;
}

.accordion:not(.bigger-acc) dt span em {
  font-family:inherit;
}

.accordion.bigger-acc dt span {
  font-family:inherit;
}

.accordion.bigger-acc dt span > div {
  position: relative;
  display:table;
  float:left;
  width:auto;
  -webkit-text-fill-color: rgba(0,0,0,0.1);
  -webkit-background-clip: text;
  background-repeat: no-repeat;
  background-image: linear-gradient(#000, #000);
    background-size: 100% 100%;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
}

.accordion.bigger-acc:hover dt span > div {
  transition: all 0.15s ease 0s;
  -webkit-transition: all 0.15s ease 0s;
  background-size: 0% 100%;
}

.accordion.bigger-acc:hover dt:hover span > div, .accordion.bigger-acc:hover dt.accordion-active span > div {
  background-size: 100% 100%;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
}

.light-content .accordion.bigger-acc dt span > div, .dark-section .accordion.bigger-acc dt span > div {
  -webkit-text-fill-color: rgba(255,255,255,0.1);
  background-image: linear-gradient(#fff, #fff);;
}

.light-content .light-section .accordion.bigger-acc dt span > div {
  -webkit-text-fill-color: rgba(0,0,0,0.1);
  -webkit-background-clip: text;
  background-repeat: no-repeat;
  background-image: linear-gradient(#000, #000);
}

.light-content .accordion dt span, .dark-section .accordion dt span {
  color:#fff;
}

.light-content .light-section .accordion dt span {
  color:#000;
}

.accordion .accordion-content {
  margin-bottom: 0;
  font-size:16px;
  color:#000;
  font-weight:400;
  line-height: 28px;
  padding-left: 0;
  padding-right: 80px;
  padding-top:10px;
  padding-bottom:5px;
  z-index: 0;
  position: relative;
}

.accordion.bigger-acc .accordion-content {
  font-size:18px;
  line-height: 30px;
}

.light-content .accordion .accordion-content, .dark-section .accordion .accordion-content {
  color:#ccc;
}

.light-content .light-section .accordion .accordion-content {
  color:#444;
}

.acc-icon-wrap {
  width: 30px;
  height: 30px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  float: none;
  opacity:1;
  -webkit-transition:opacity 0.2s ease 0s;
    transition:opacity 0.2s ease 0s;
}

.accordion.bigger-acc .acc-icon-wrap {
  height: 50px;
  width: 50px;
}


.accordion dt:hover .acc-icon-wrap, .accordion dt.accordion-active .acc-icon-wrap {
  opacity:1;
}

.acc-button-icon {
  height: 30px;
  width: 30px;
  color: #000;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  background: rgba(0,0,0,0.1);
  border-radius: 50px;
  -webkit-transition:background 0.2s ease 0s, color 0.2s ease 0s;
    transition:background 0.2s ease 0s, color 0.2s ease 0s;
}

.accordion.bigger-acc .acc-button-icon {
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 16px;
  background: rgba(0,0,0,0.1);
  border-radius: 50px;
  
}

.accordion dt:hover .acc-button-icon, .accordion dt.accordion-active .acc-button-icon {
  background: rgba(0,0,0,1);
  color: #fff;
}

.light-content accordion .acc-button-icon, .dark-section .accordion .acc-button-icon {
  background: rgba(255,255,255,0.1);
}

.light-content .accordion dt:hover .acc-button-icon, .dark-section .accordion dt.accordion-active .acc-button-icon {
  background: rgba(255,255,255,1);
  color: #000;
}

.light-content .light-section accordion .acc-button-icon {
  background: rgba(0,0,0,0.1);
}

.light-content .light-section dt:hover .acc-button-icon, .light-content .light-section .accordion dt.accordion-active .acc-button-icon {
  background: rgba(0,0,0,1);
  color: #fff;
}

.light-content .accordion .acc-button-icon, .dark-section .accordion .acc-button-icon {
  color: #fff;
}

.light-content .light-section .accordion .acc-button-icon {
  color:#000;
}

.acc-button-icon i {
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  -webkit-transition:transform 0.2s ease 0s;
    transition:transform 0.2s ease 0s;
}

dt.accordion-active .acc-button-icon i {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}



/*--------------------------------------------------
Justified Gallery
---------------------------------------------------*/	

.justified-gallery {
  box-sizing: border-box;
  padding: 0 0px;
   width: calc(100% + 20px) !important;
    margin-left: -10px;
}

.collage-thumb {
  position:relative;
  overflow:hidden;
  cursor:pointer;
}

.collage-thumb:hover .thumb-info {
  opacity:1;
  bottom:-5px;
}

.thumb-info {
  position:absolute;
  bottom:-10px;
  left:0;
  width:100%;
  opacity:0;
  color: #fff!important;
  padding: 50px 20px 20px;
  box-sizing:border-box;
  font-size:12px;
  font-weight: 500;
  transition:all 0.2s ease-in-out 0s;
    -webkit-transition:all 0.2s ease-in-out 0s;
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.55) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.55))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.55) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.55) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.55) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.55) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}

/*--------------------------------------------------
Team Members
---------------------------------------------------*/	


.team-members-list  {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing:border-box;
  margin: 0 auto;
  padding: 0px;
  overflow:hidden;
  pointer-events: none;
}

.team-members-list li {
  list-style:none;
  position: relative;
  margin:0;
  width:100%;
  cursor:pointer;
  pointer-events: initial;
  padding:10px 0px 40px 0px;
  box-sizing:border-box;
  border-bottom:1px solid;
  border-color:rgba(0,0,0,0.1);
}

.team-members-list li:last-child {
  border-bottom:0px solid;
}

.light-content .team-members-list li, .dark-section .team-members-list li {
  border-color:rgba(255,255,255,0.15);
}

.light-content .light-section .team-members-list li {
  border-color:rgba(0,0,0,0.1);
}

.full > .team-members-list li, .full_elementor .team-members-list li {
  padding:10px 80px 40px 80px;
}

.team-member {
  font-size: calc(1rem + 8vw);
    line-height: calc(1rem + 7.5vw);
  font-weight: 500;
  position: relative;
  display:table;
  margin-bottom:0px;
  vertical-align: top;
  width:100%;	
  z-index:10;
}

.full > .team-member, .full_elementor .team-member {
  font-size: calc(1rem + 4.15vw);
  line-height: calc(1rem + 5.5vw);
}

.team-member > div {
  position: relative;
  display:table;
  margin:0 auto;		
  width:auto;
  -webkit-text-fill-color: rgba(0,0,0,0.1);
  -webkit-background-clip: text;
  background-repeat: no-repeat;
  background-image: linear-gradient(#000, #000);
    background-size: 100% 100%;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
}

.uppercase-titles .team-member > div {
  text-transform:uppercase;
}

.team-members-list:hover li .team-member > div {
  transition: all 0.15s ease 0s;
  -webkit-transition: all 0.15s ease 0s;
  background-size: 0% 100%;
}

.team-members-list:hover li:hover .team-member > div {
  background-size: 100% 100%;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
}

.light-content .team-member > div, .dark-section .team-member > div {
  -webkit-text-fill-color: rgba(255,255,255,0.1);
  background-image: linear-gradient(#fff, #fff);;
}

.light-content .light-section .team-member > div {
  -webkit-text-fill-color: rgba(0,0,0,0.1);
  -webkit-background-clip: text;
  background-repeat: no-repeat;
  background-image: linear-gradient(#000, #000);
}

.light-section .team-member {
  color:#000;
}

.dark-section .team-member {
  color:#fff;
}

.team-members-list:hover li .team-member {
  opacity:0.3;
}

.team-members-list li:hover .team-member {
  opacity:1;
}

.team-member span {
  font-size: 16px;
  font-weight: 500;
  line-height: inherit;
  position: absolute;
  left:0;
  top:0;
  color: #000;
  display: block;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
}

.team-member span:last-child {
  left:auto;
  right:0;
}

.team-members-list:hover li .team-member > span {
  opacity:0.3;
}

.team-members-list:hover li:hover .team-member > span {
  opacity:1;
}

.light-section .team-member span {
  color:#000;
}

.dark-section .team-member span {
  color:#fff;
}

.team-members-list .hover-reveal {
  position: fixed;
  width: 300px;
  height: 400px;
  pointer-events: none;
  opacity: 0;
}

.swiper-container.team-looped-carousel {		
  cursor:grab;
  }

.drag-cursor .swiper-container.team-looped-carousel {
  cursor:grabbing;
  }

.team-looped-carousel .swiper-slide {        
  text-align:left;
  }

.team-caption {
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  height:auto;
  box-sizing:border-box;
  padding:10px 30px 30px 30px;
}

.team-caption h5, .team-caption p {
  margin-bottom:0;
}

/*--------------------------------------------------
Contact Formular
---------------------------------------------------*/	

.text-align-center #contact-formular {
  margin:0 auto;
  max-width:800px;
}

#contact-formular::after {
  clear: both;
  content: " ";
  display: table;
}

#contactform {
  position:relative;
}

.name-box, .email-box, .message-box {
  position:relative;
  display:block;
}

.name-box, .email-box  {
  width: 47%;
  float: left;
  margin-bottom: 20px;
  margin-right: 6%;
  position: relative;
}

.name-box::after, .email-box::after {
  clear: both;
  content: " ";
  display: table;
}

.email-box {
  margin-right:0px;
}

.message-box {
  float:left;
  width:100%;		
  margin-top:60px;
}

.verify-box {
  float:left;
  width:100%;		
  margin-bottom:60px;
}

.text-align-center .verify-box {
  float: none;
  width: auto;
  text-align: center;
  display: table;
  margin: 0 auto;
  margin-bottom: 60px;
}

.verify-sum {
  margin:0;
  padding:0;
  float:left;
}

.verify-sum li {
  list-style:none;
  display:block;
  float:left;
  color:#fff;
  width:34px;
  height:34px;
  line-height:34px;
  text-align:center;
  border-radius:3px;
  background-color:#000;
  margin-bottom:0;
}

.light-content .verify-sum li, .dark-section .verify-sum li {
  color:#000;
  background-color:#fff;
}

.light-content .light-section .verify-sum li {
  color:#fff;
  background-color:#000;
}

.verify-sum li:nth-child(2), .verify-sum li:nth-child(4) {
  width:30px;
  color:#444;
  background-color:transparent;
}

.light-content .verify-sum li:nth-child(2), .light-content .verify-sum li:nth-child(4), .dark-section .verify-sum li:nth-child(2), .dark-section .verify-sum li:nth-child(4) {
  color:#fff;
  background-color:transparent;
}

.light-content .light-section .verify-sum li:nth-child(2), .light-content .light-section .verify-sum li:nth-child(4) {
  color:#444;
  background-color:transparent;
}

input#verify {
  float: left;
  width: 34px;
  padding: 0;
  height: 34px;
  text-align: center;
  line-height: 34px;
  border: none;
  border-radius:3px;
  background-color:#000;
  color:#fff;
}

.light-content input#verify, .dark-section  input#verify {
  background-color:#fff;
  color:#000;
}

.light-content .light-section  input#verify {
  background-color:#000;
  color:#fff;
}

#contact-formular .required {
  float:left;
  width:auto;
  font-size: 14px;
  line-height:34px;
  margin-left:20px;
  margin-bottom:0;
}

#contact-formular .text-align-center .required {
  float: left;
  margin-left: 0;
  display: block;
  clear: both;
  text-align: center;
  width: 100%;
  padding-top: 10px;
}

input, textarea {
  color: rgba(0,0,0,1);
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  padding: 12px 20px 12px 20px;
  padding-left:0;
  width: 100%;
  border-radius:0px;
  margin-bottom:0px;
  border:none;		
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  box-sizing: border-box;
  background-color:transparent;
  outline:none;
  border-bottom:1px solid rgba(0,0,0,0.3);
}

.bigger-form input, .bigger-form textarea {
  font-size: 48px;
  font-weight: 500;
  height: 100px;
  padding: 0;
}

input[type="search"] {
  padding-left:0px;
}

.light-content input, .light-content textarea, .dark-section input, .dark-section textarea {
  color:rgba(255,255,255,1);
  border-bottom:1px solid rgba(255,255,255,0.3);
}

.light-content .light-section input, .light-content .light-section textarea {
  color:rgba(0,0,0,1);
  border-bottom:1px solid rgba(0,0,0,0.3);
}

::placeholder, .light-content .light-section ::placeholder { 
  color: #000;
  opacity: 1; 
}

.light-content ::placeholder, .dark-section ::placeholder { 
  color: #fff;
  opacity: 1; 
}

.light-content :-ms-input-placeholder, .dark-section :-ms-input-placeholder { 
  color: #fff;
}

.light-content .light-section :-ms-input-placeholder { 
  color: #000;
  opacity: 1; 
}

.light-content ::-ms-input-placeholder { 
  color: #fff;
}

:-ms-input-placeholder { 
  color: #000;
}

.input_label {
  background-color: #000;
  height: 2px;
  left: 0;
  position: absolute;
  top: 49px;
  width: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.bigger-form .input_label {
  top: 99px;
}

.light-content .input_label, .dark-section .input_label {
  background-color: #fff;
}

.light-content .light-section .input_label {
  background-color: #000;
}

.input_label.slow {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

input:focus + .input_label, textarea:focus + .input_label {
  width:100%;		
}

input:focus, textarea:focus {
  color:#000;
}

.light-content input:focus, .light-content textarea:focus, .dark-section input:focus, .dark-section textarea:focus {
  color:#fff;
}

.light-content .light-section input:focus, .light-content .light-section textarea:focus {
  color:#000;
}

textarea {
  resize:none;
  margin-bottom:0;
  height:50px;
  box-sizing: border-box;
  padding: 14px 20px 6px 20px;
  padding-left:0;
  margin-bottom:40px;
}

textarea:after {
  background-color: #000;
  content: "";
  height: 2px;
  left: 0px;
  position: absolute;
  top: 20px;
  width: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="submit"] {
  margin:0;
  background:transparent;
  border:none;
  color:#fff;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 500;
  padding-left:50px;
  padding-right:50px;
  height:100%;
}

.light-content input[type="submit"], .dark-section input[type="submit"] {
  color:#000;
}

.light-content .light-section input[type="submit"] {
  color:#fff;
}

.outline input[type="submit"] {
  color:#000;
}

.light-content .outline input[type="submit"], .dark-section .outline input[type="submit"] {
  color:#fff;
}

.light-content .light-section .outline input[type="submit"] {
  color:#000;
}

.error_message {				
  margin-bottom:30px;
  font-size:13px;		
  color:#e93735;		
  font-weight:bold;		
}

#success_page p, #success_page p strong {		
  color:#fff;		
  margin-top:20px;		
}

#message fieldset {		
  border:none;		
}


/*--------------------------------------------------
Google Map
---------------------------------------------------*/	

#map_canvas {
  display: block;
  height:100vh;
  position:relative;
  width: 100%;
}

.rounded-borders #map_canvas {
  border-radius:8px;
  overflow:hidden;
}

#hero-image.bgrelative #map_canvas {
  position:relative!important;
}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display:none;
}

.gmnoprint div {
  background:none !important;
}

.gm-style button {
  display:none!important;
}


.services-icon i {
  font-size:24px;
  margin-bottom:20px;
}

.service-info-text {
  margin-bottom:40px;
}

/*--------------------------------------------------
Flex Lists
---------------------------------------------------*/		

.flex-lists-wrapper, .flex-list-wrapper {
  position:relative;
  display:block;
  margin-left: calc(50% - 50vw)!important;
  margin-right: calc(50% - 50vw)!important;
  max-width: 1000%!important;
  width: 100vw!important;
  overflow:hidden;
  border-top: 1px solid rgba(255,255,255,0.15);
}

.light-content .flex-lists-wrapper , .dark-section .flex-lists-wrapper, .light-content .flex-list-wrapper , .dark-section .flex-list-wrapper {
  border-top: 1px solid rgba(255,255,255,0.15);
}

.light-content .light-section .flex-lists-wrapper, .light-content .light-section .flex-list-wrapper {
  border-top: 1px solid rgba(0,0,0,0.1);
}

.flex-list {
  position: relative;
  padding: 35px 80px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.light-content .flex-list , .dark-section .flex-list {
  border-bottom: 1px solid rgba(255,255,255,0.15);
}

.light-content .light-section .flex-list {
  border-bottom: 1px solid rgba(0,0,0,0.1);
  background-color:#fff;
}

.flex-list::after {
  content:"";
  position: absolute;
  width: 100%;
  height:0;
  left:0;
  bottom: 0;
  z-index: 0;
  background-color: #000;
  -webkit-transition: all 0.2s ease-out 0s;	
  transition: all 0.2s ease-out 0s;
}

.light-content .flex-list::after , .dark-section .flex-list::after {
  background-color: #fff;
}

.light-content .light-section .flex-list::after {
  background-color: #000;
}

.flex-list:hover::after {
  height: 100%;
}

.flex-list > span {
  z-index: 10;
  mix-blend-mode: difference;
  color: #fff;
}

.flex-list-left {
  width:15%;
  text-align:left;
}

.flex-list-center {
  width:70%;
  font-size:20px;
  text-align:center;
}

.flex-list-right {
  width:15%;
  text-align:right;
}


/*--------------------------------------------------
Moving Gallery
---------------------------------------------------*/	



.fw-gallery .wrapper-gallery {
  display: inline-flex;
  align-items: flex-end;
  margin:0;
  padding:0;
  clear:both;
}

.bw-gallery .wrapper-gallery {
  display: inline-flex;
  align-items: flex-start;
  margin:0;
  padding:0;
}

.moving-gallery li {
  position:relative;
  width: 25vw;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing:border-box;
  margin:10px 0;
  list-style:none;
}

.fw-gallery.random-sizes li:first-child, .bw-gallery.random-sizes li:first-child {
  padding-left: 0px;
}

.fw-gallery.random-sizes li:last-child, .bw-gallery.random-sizes li:last-child {
  padding-right: 0px;
}

.fw-gallery.random-sizes li:nth-child(1) {
  width: 25vw;
}

.fw-gallery.random-sizes li:nth-child(2) {
  width: 30vw;
}

.fw-gallery.random-sizes li:nth-child(3) {
  width: 45vw;
}

.fw-gallery.random-sizes li:nth-child(4) {
  width: 25vw;
}

.bw-gallery.random-sizes li:nth-child(1) {
  width: 25vw;
}

.bw-gallery.random-sizes li:nth-child(2) {
  width: 45vw;
}

.bw-gallery.random-sizes li:nth-child(3) {
  width:30vw;
}

.bw-gallery.random-sizes li:nth-child(4) {
  width: 25vw;
}

.moving-gallery li img {
  height:auto;
  margin:0px;
}

.moving-gallery li img {
    border: 1px solid rgba(255,255,255,0.15);
}

.rounded-borders .moving-gallery li img {
  border-radius:12px;
}

.moving-gallery-caption {
  position: absolute;
  height: auto;
  padding: 30px;
  box-sizing: border-box;
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;
  opacity:0;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

.moving-gallery li:hover .moving-gallery-caption {
  opacity:0.4;
}



/*--------------------------------------------------
Zoom Gallery
---------------------------------------------------*/		


.zoom-gallery {
  position:relative;
  margin:0;
  padding:0;
  clear:both;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  max-width: 1000%;
  width: calc(100vw);
  height: auto;
  pointer-events:none;
}

.zoom-wrapper-gallery {
  position:relative;
  border-right: 0px solid #f00;
  box-sizing:border-box;
  margin:0;
  padding:0 70px;
  clear:both;
  width:100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.zoom-wrapper-gallery li {
  position:relative;
  display: block;
  box-sizing:border-box;
  padding:0 10px;
  margin:0;
  list-style:none;
  z-index:0;	
  width: 50%;
  max-width: 50%;
  height:100%;
  float:left;
  display:flex;
  align-items:center;
  
}

.zoom-wrapper-gallery li.zoom-center {
  z-index:1;
}

.zoom-wrapper-gallery li .zoom-img-wrapper {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height:100%;
  position: relative;
  overflow: hidden;		
  background-color:rgb(102, 102, 102);
}

.rounded-borders .zoom-wrapper-gallery li .zoom-img-wrapper {
  border-radius: 8px;
}

.zoom-wrapper-gallery.end-state  li.zoom-center .zoom-img-wrapper {
  
}

.zoom-wrapper-gallery li .zoom-img-wrapper img {
  width:100%;
  height: 100%;
  position:relative;
  display:block;
  z-index:100;	
  object-position: center; 
  object-fit: cover;
}

.zoom-wrapper-thumb {
  width:100%;
  height: 100vh;
  position:absolute;
  top:0;
  display:block;
}

.zoom-wrapper-thumb .zoom-img-wrapper {
  width:100%;
  height: 100%;
  position:absolute;
  overflow:hidden;
}

.rounded-borders .zoom-wrapper-thumb .zoom-img-wrapper {
  border-radius: 8px;
}

.zoom-wrapper-thumb .zoom-img-wrapper img {
  width:100%;
  height: 100%;
  position:relative;
  display:block;
  z-index:100;	
  object-position: center; 
  object-fit: cover;
}


/*--------------------------------------------------
Slowed Pin
---------------------------------------------------*/	

.slowed-pin {
  position:relative;
  overflow: hidden;
}

.slowed-pin > .pin-spacer {
  pointer-events:none;
}

.slowed-text {
  position:absolute;
  height:auto;
  min-height:100vh;
  width:100%;
  max-width:980px;		
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing:border-box;
  padding:8vw 20px;
  z-index:1;
  pointer-events:none;
  margin:auto;
  left:0;
  right:0;
}

.slowed-text-wrapper {
  position:relative;
  display:block;
}

.slowed-images {
  position: relative;
  top: 0;
  width: 100%;
  height:auto;
  box-sizing:border-box;
  overflow: hidden;
  z-index:0;
  margin:auto;
}

.slowed-image {
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding:0 0px;
  margin:150px 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.slowed-image:nth-of-type(2n+2) {
    align-items: flex-end;
}

.slowed-image img {
  width: auto;
  max-width: 40%!important;
  height: auto!important;
  display: block;
  cursor:pointer;
  margin-bottom:10px;
  -webkit-transform:scale(0.95) translateY(30%);
  transform: scale(0.95) translateY(30%);
}

.rounded-borders .slowed-image img {
  border-radius: 8px;
}

/*--------------------------------------------------
Horizontal Panels
---------------------------------------------------*/		

.panels {
  position:relative;
  margin-left: calc(50% - 50vw)!important;
  margin-right: calc(50% - 50vw)!important;
  max-width: 1000%!important;
  width: calc(100vw)!important;		
}

.panels-container {
  position:relative;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  justify-content: flex-start;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0;
  overflow: hidden;
  box-sizing:border-box;
}

.panels-container > .panel {
  position: relative;
  width: auto;
  height: 100%;
  margin:0 10px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing:border-box;
  flex-direction: column;
}

.panels-container > .panel:first-child {
  margin-left:80px;
}

.panels-container > .panel:last-child {
  margin-right:80px;
}

.panels-container .panel img {
  width:100%;
  height: 100%;
  position:relative;
  display:block;
  object-position: center; 
  object-fit: cover;		
}

.rounded-borders .panels-container .panel img {
  border-radius:8px;
}

.panels-container .panel:first-child img {
  margin-left:0px;
}

.panels-container .panel:last-child img {
  margin-right:0px;
}

/*--------------------------------------------------
Team Panels
---------------------------------------------------*/

#team-panels .panel-content-wrapper {
  position: relative;
  width: auto;
  height: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing:border-box;
  flex-direction: column;
  padding-left:30px;
  padding-right:30px;
}

#team-panels .panels-container .panel:first-child .panel-content-wrapper {
  padding-left:80px;
}

#team-panels .panels-container .panel:last-child .panel-content-wrapper {
  padding-right:80px;
}

#team-panels .panel-content {
  position: absolute;
  width: 100%;
  height: auto;
  display: block;
  box-sizing:border-box;
  padding:10px 30px;
  bottom:-50px;
  left:0;		
  pointer-events: none;
}

#team-panels .panels-container .panel:first-child .panel-content-wrapper .panel-content {
  padding-left:80px;
}

#team-panels .panels-container .panel:last-child .panel-content-wrapper .panel-content {
  padding-right:80px;
}

.team-name-panel, .team-cat-panel {
  font-size:18px;
  line-height: 24px;
  display:table;
  width:auto;
  float:left;
}

.team-cat-panel {
  font-size:14px;
  opacity:0.6;
  float:right;
}

#team-panels .panels-container .panel .panel-image {
  width: auto;
  height: 100%;
  position:relative;
  overflow:hidden;
}

#team-panels .panels-container .panel img {
  height: 100%;
  margin:0;
}

.rounded-borders #team-panels .panels-container .panel img {
  border-radius:8px;
}


/*--------------------------------------------------
Pinned Gallery
---------------------------------------------------*/	


.pinned-image {
  box-sizing:border-box;
  padding-bottom:300px;		
  position:relative;
}

.pinned-image:first-child {
  padding-top:0;
}

.pinned-image:last-child {
  z-index:10;
  padding-bottom:0px;		
}

.rounded-borders .pinned-image img {
  border-radius:8px;
}

/*--------------------------------------------------
Reveal Gallery
---------------------------------------------------*/		

.reveal-gallery {
  position:relative;
  width:100%;
  height:auto;
  display:block;
  margin:0 auto;
}

.reveal-img {
  width:auto;
  height:auto;
  position:absolute;
  display: block;
  top:0;
  left: auto;
  right: auto;
  margin: auto;
  z-index:0;
  opacity: 0.95;
}

.reveal-img-fixed {
  width:50%;
  max-width:600px;		
  position:relative;
  margin: 0 auto;
  z-index:10;
}

.reveal-img:first-child {
  left:0;
  transform:rotate(-4deg)
}

.reveal-img:last-child {
  right:0;
  transform:rotate(4deg)
}

.reveal-gallery img {
  width:auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position:relative;
  display:block;
  object-position: center; 
  object-fit: contain;
  margin: auto;
}

.rounded-borders .reveal-gallery img {
  border-radius:8px;
}


/*--------------------------------------------------
Clipped Immaged
---------------------------------------------------*/			

.clipped-image-wrapper {
  position:relative;
  margin-left: calc(50% - 50vw)!important;
  margin-right: calc(50% - 50vw)!important;
  max-width: 1000%!important;
  width: 100vw!important;
  overflow:hidden;
}

.clipped-image-pin {
  position:relative;
  display:block;
}

.clipped-image-size {
  width:100%;
  height:100%;
  position:relative;
  display:block;
}

.clipped-image {
  position:relative;
  z-index:0;
  width:100vw;
  height:100vh;
  display:block;
  overflow:hidden;
  clip-path: circle(22%);
  margin:0 auto;	
}

.clipped-image img {
  width:100%;
  height: 100%!important;
  position:relative;
  display:block;
  z-index:0;	
  object-position: center; 
  object-fit: cover;		
}

.clipped-image-gradient {
  position:absolute;
  height:30%;
  width:100%;
  bottom:0;
  opacity:0;
  background-color:#000;
  z-index:10;
  -webkit-mask-image: linear-gradient(transparent, black 85%);
  mask-image: linear-gradient(transparent, black 85%);

}

.clipped-image-content {
  position:relative;
  width:100%;
  height:auto;
  padding-bottom:40px;
  margin:0 auto;
  z-index:10;
}

/*--------------------------------------------------
Carousel Shortcode
---------------------------------------------------*/		

.carousel-shortcode-wrapper {
  position:relative;
  display:inline-block;
  margin-left: calc(50% - 50vw)!important;
  margin-right: calc(50% - 50vw)!important;
  max-width: 1000%!important;
  width: 100vw!important;
  height:auto;
  
}

.carousel-shortcode-pin {
  position:relative;
  height:100%;
  width:100%;
  top:0;
  left:0;
  display:block;
  
}

.carousel-shortcode-thumbs {
  position:relative;
  height:100vh;
  width:100%;
  padding:20px 40px;
  margin:0 auto;
  display:flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  box-sizing:border-box;
  z-index:10;
  mask-image: linear-gradient(#0000, black 15%, black 85%, #0000 100%);
    -webkit-mask-image: linear-gradient(#0000, black 15%, black 85%, #0000 100%);
  pointer-events:none;
}

.carousel-shortcode-thumbs .clapat-item {
  width: calc(20% - 80px);
  margin:60px 40px;
}

.carousel-shortcode-thumbs.is_active .clapat-item {
  pointer-events:initial;
}

.carousel-shortcode-thumbs .clapat-item .slide-inner {
  padding-top: 0;
  width: 100%;
  height: auto;
  margin:0;
  overflow:visible;
  aspect-ratio: 10/14;
}

.carousel-shortcode-caption {
  position:absolute;
  height:100%;
  width:100%;
  top:0;
  left:0;
  display:flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  z-index:0;
}

.carousel-shortcode-title-hide, .carousel-shortcode-title-show {
  position:absolute;		
  font-size: calc(1rem + 4.15vw);
  line-height: calc(1rem + 4.5vw);
  font-weight:500;
  display:block;
  width:auto;		
}

.uppercase-titles .carousel-shortcode-title-hide, .uppercase-titles .carousel-shortcode-title-show {
  text-transform:uppercase;
}

.carousel-shortcode-title-show {
  position:relative;
  font-size: calc(1rem + 1.15vw);
  line-height: calc(1rem + 1.5vw);
}

.light-content .carousel-shortcode-title-hide, .dark-section .carousel-shortcode-title-hide, .light-content .carousel-shortcode-title-show, .dark-section .carousel-shortcode-title-show {
  color:#fff;
}

.light-content .light-section .carousel-shortcode-title-hide, .light-content .light-section .carousel-shortcode-title-show {
  color:#000;
}

.carousel-shortcode-title-hide div, .carousel-shortcode-title-show div {
  position:relative;
  display:block;overflow:hidden;
}

.carousel-shortcode-title-hide span {
  position:relative;
  display:block;
  opacity:1;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
}

.carousel-shortcode-title-show span {
  position:relative;
  display:block;
  opacity:0;
  transform: translateY(200px);
  -webkit-transform: translateY(200px);
}

.carousel-shortcode-cta {
  position:relative;
  display:block;
  opacity:0;
  transform: scale(1) translateY(50px);
  -webkit-transform:  scale(1) translateY(50px);
}


/*--------------------------------------------------
List Rotator
---------------------------------------------------*/

.list-rotator-wrapper {
  position:relative;
  display:block;
  margin-left: calc(50% - 50vw)!important;
  margin-right: calc(50% - 50vw)!important;
  max-width: 1000%!important;
  width: 100vw!important;
  overflow:hidden;
}

.list-rotator-title {
  position:absolute;
  margin:0 auto;
  display:table;
  top:45px;
  left:0;
  right:0;
  padding-top:45px;
  z-index: 100;
  line-height: 20px;
  font-size: 14px;
  font-weight:500;
  color: #fff;
  padding:4px 16px;
  box-sizing:border-box;
  border-radius:30px;
  opacity:0;
}

.list-rotator-height {
  display: flex;
  justify-content: center;
  pointer-events: none;
  position:relative;
}

.list-rotator-pin {
  position: relative;
  height: 100vh;
  width: 100%;
  mask-image: linear-gradient(#0000 0%, #0000 5%, #0000 5%, black 20%, black 85%, #0000 99%, #0000 99%, #0000 100%);
    -webkit-mask-image: linear-gradient(#0000 0%, #0000 5%, #0000 5%, black 20%, black 85%, #0000 99%, #0000 99%, #0000 100%);
}


.list-rotator {
  position: relative;
  display:block;
  width: 100%;
  height: auto;
  box-sizing:border-box;
  padding-top:20vh;
  padding-bottom:15vh;
  margin: 0;
}

.list-rotator li {
  color: #000;
  list-style: none;
  position: relative;
  width: 100%;
  text-align: center;
  font-size: calc(1rem + 8vw);
    line-height: calc(1rem + 7.5vw);
  margin: 0;
  opacity:1;
}

.list-rotator li.in-view {
  opacity:1;
}

.light-content .list-rotator li , .dark-section .list-rotator li {
  color: #fff;
}

.light-content .light-section .list-rotator li {
  color: #000;
}

/*--------------------------------------------------
Move Thumbs Gallery
---------------------------------------------------*/

.move-thumbs-wrapper {
  margin-left: calc(50% - 50vw)!important;
  margin-right: calc(50% - 50vw)!important;
  max-width: 1000%!important;
  width: 100vw!important;
}

.start-thumbs-caption {
  position:relative;
  width: 100%;
   height:50vh;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.start-thumbs-wrapper {
  position:relative;
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing:border-box;
  padding:0 40px;
}

.start-move-thumb {
  box-sizing:border-box;
  position:relative;
  width: calc(25% - 80px);
   height:calc(25vw - 40px);
  margin:0 40px;
  margin-bottom:40px;
}

.start-move-thumb:nth-of-type(3n + 2) {		
  width: calc(15% - 80px);
  height:calc(15vw - 40px);
  margin-top: 50vh;
}

.start-move-thumb:nth-of-type(3n + 3) {		
  width: calc(35% - 80px);
  height:calc(35vw - 40px);
  margin-top: 25vh;
}

.start-move-thumb .overlapping-image-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
}

 .end-move-thumb .move-thumb-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
}

.rounded-borders .start-move-thumb .move-thumb-inner, .rounded-borders .end-move-thumb .move-thumb-inner {
  border-radius:12px;
  overflow:hidden;
}

.end-thumbs-wrapper {
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top:100vh;
  padding-left:40px;
  padding-right:40px;
  box-sizing:border-box;
}

.end-move-thumb {
  box-sizing:border-box;	
  position:relative;
  width: calc(33.33% - 80px); 
  height:calc(33.33vw - 80px); 
  display:flex;
  align-items: center;
  justify-content: center;
  margin:40px;
}



/*--------------------------------------------------
Responsive
---------------------------------------------------*/			
    


@media only screen and (max-width: 1466px) {


.button-border span {
  padding: 0 26px;
}

.clients-table li {
  width: calc(100% / 4 - 40px);
  margin: 20px;
  padding: 0;
}

.accordion.bigger-acc dt {
  margin-top: 30px;
  padding-top: 30px;
}

.accordion.bigger-acc dt:first-child {
  padding-top: 30px;
}

.full > .team-members-list li, .full_elementor .team-members-list li {
  padding:40px 60px;
}

.team-members-list .hover-reveal {
  width: 250px;
  height: 333px;
}

span.has-hover-image .hover-reveal {
  width: 300px;
  height: 188px;
}

span.has-hover-image.vertical .hover-reveal {
  width: 250px;
  height: 333px;
}

.flex-list {
  padding: 35px 60px;
}

.panels-container > .panel:first-child {
  margin-left:60px;
}

.panels-container > .panel:last-child {
  margin-right:60px;
}

.zoom-wrapper-gallery {
  padding: 0 50px;
}

.start-thumbs-wrapper {
  padding: 0 30px;
}

.start-move-thumb {
  width: calc(25% - 60px);
   height:calc(25vw - 30px);
  margin:0 30px;
  margin-bottom:30px;
}

.start-move-thumb:nth-of-type(3n + 2) {		
  width: calc(15% - 60px);
  height:calc(15vw - 30px);
  margin-top: 50vh;
}

.start-move-thumb:nth-of-type(3n + 3) {		
  width: calc(35% - 60px);
  height:calc(35vw - 30px);
  margin-top: 25vh;
}

.end-thumbs-wrapper {
  padding-left: 30px;
  padding-right: 30px;
}

.end-move-thumb {
  width: calc(33.33% - 60px);
  height: calc(33.33vw - 60px);
  margin: 30px;
}

}


@media only screen and (max-width: 1024px) {

.content-slider.looped-carousel .clapat-slide {
  padding:0 40px;
}

.content-slider.small-looped-carousel .clapat-slide {
  padding:0 40px;
}

.disable-cursor .full .looped-carousel .clapat-controls, .disable-cursor .full_elementor .looped-carousel .clapat-controls {
  width: calc(100% - 80px);
}

.disable-cursor .full .small-looped-carousel .clapat-controls, .disable-cursor .full_elementor .small-looped-carousel .clapat-controls {
  width: calc(100% - 80px);
  max-width:520px;
}		
  
textarea {
  margin-bottom: 30px;
}

.full .video-wrapper video.bgvid, .full_elementor .video-wrapper video.bgvid {
  position: relative;
  margin-top: 0;
  left: 0;
  transform: translateX(0) translateY(0);
}

.full .control, .full_elementor .control {
  bottom: 10px;
  width: calc(100% - 100px);
}

#map_canvas {
  height:50vh;
}

.clients-table li {
  width: calc(100% / 4);
  margin: 0px;
  padding: 0;
}

.clients-table li:hover .overlay {
  transform:scale(0.85);
  -webkit-transform:scale(0.85);
  opacity:1;
}

.accordion dt span {
  max-width: none;
}

.accordion .accordion-content {
  padding-right: 0;
}

.accordion.bigger-acc dt {
  margin-top: 30px;
  padding-top: 30px;
}

.accordion.bigger-acc dt:first-child {
  padding-top: 30px;
}

.full > .team-members-list li, .full_elementor .team-members-list li {
  padding:40px 40px;
}

.team-members-list .hover-reveal {
  width: 200px;
  height: 266px;
}

.team-member {
    font-size: calc(1rem + 12vw);
  line-height: calc(1rem + 11.5vw);
}

span.has-hover-image .hover-reveal {
  width: 250px;
  height: 156px;
}

span.has-hover-image.vertical .hover-reveal {
  width: 200px;
  height: 266px;
}

.has-animation.has-cover img {
  -webkit-transition:scale(1);
  transform:scale(1);
}

.flex-list {
  padding: 30px 40px;
}

.panels-container > .panel:first-child {
  margin-left:40px;
}

.panels-container > .panel:last-child {
  margin-right:40px;
}

.zoom-wrapper-gallery {
  padding: 0 30px;
}

.list-rotator-pin {
    height: auto;
}

.list-rotator li {
  font-size: calc(1rem + 12vw);
  line-height: calc(1rem + 11.5vw);
}

.start-thumbs-wrapper {
    padding: 0 20px;
  }

 .start-move-thumb, .start-move-thumb:nth-of-type(3n+2), .start-move-thumb:nth-of-type(3n+3) {
  width: calc(33.33% - 40px);
  height: calc(33.33vw - 40px);
  margin: 25px 20px;
  }

.end-thumbs-wrapper {
    display: none;
  }

}


@media only screen and (max-width: 767px) {

.content-slider.looped-carousel .clapat-slide {
  padding:0 30px;
}

.content-slider.small-looped-carousel .clapat-slide {
  padding:0 30px;
}

.disable-cursor .full .looped-carousel .clapat-controls, .disable-cursor .full_elementor .looped-carousel .clapat-controls {
  width: calc(100% - 60px);
}

.disable-cursor .full .small-looped-carousel .clapat-controls, .disable-cursor .full_elementor .small-looped-carousel .clapat-controls {
  width: calc(100% - 60px);
  max-width:540px;
}

.content-slider .clapat-pagination-bullet .parallax-wrap {
  width: 20px;
  height: 20px;
}

.content-slider .clapat-pagination-bullet .parallax-wrap {
  width: 20px;
  height: 20px;
}
  
.video-wrapper {
    max-height: 50vh;
}

.control {
  display:none;
}

.name-box, .email-box {
  width: 100%;
  margin-right: 0;
}

.clients-table li {
    width: 50%;
}

.clients-table::before, .clients-table::after {
    height: 10px;
}

.empty-space, .empty-space.tx { 
  height:60px;
}

input[type="text"], textarea {
    font-size: 14px;
}

.accordion dt {
    font-size: 18px;
}

.accordion.bigger-acc dt {
  margin-top: 25px;
  padding-top: 25px;
}

.accordion.bigger-acc dt:first-child {
  padding-top: 25px;
}

.accordion.bigger-acc .acc-button-icon {
  height: 30px;
  width: 30px;
  line-height: 30px;
  font-size: 12px;
  border-radius: 30px;
}

.acc-button-icon i {
  height: 30px;
  line-height: 30px;
  width: 30px;
}

.full > .team-members-list li, .full_elementor .team-members-list li {
  padding:30px 30px;
}

.team-member > div {
  float: none;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 4px;
}

.team-member span {
  position:relative;
  float: none;
  line-height: 24px;
}

.team-member {
    font-size: calc(1rem + 13vw);
  line-height: calc(1rem + 12.5vw);
}

.team-members-list .hover-reveal {
  width: 150px;
  height: 200px;
}

span.has-hover-image .hover-reveal {
  width: 200px;
  height: 125px;
}

span.has-hover-image.vertical .hover-reveal {
  width: 150px;
  height: 200px;
}

.flex-list {
  padding: 30px 30px;
  flex-direction: column;
}

.flex-list-left, .flex-list-center, .flex-list-right {
  width: 100%;
  text-align: left;
  line-height:30px;
}

.slowed-image {
    margin: 100px 0;
}

.slowed-image img {
  max-width: 50%!important;
}

.moving-gallery li {
  width: 50vw;
}

.panels-container > .panel:first-child {
  margin-left:30px;
}

.panels-container > .panel:last-child {
  margin-right:30px;
}

.zoom-wrapper-gallery {
  padding: 0 20px;
}

.list-rotator li {
  font-size: calc(1rem + 13vw);
  line-height: calc(1rem + 12.5vw);
}

.start-thumbs-wrapper {
    padding: 0 15px;
  }

 .start-move-thumb, .start-move-thumb:nth-of-type(3n+2), .start-move-thumb:nth-of-type(3n+3) {
  width: calc(80% - 30px);
  height: calc(80vw - 30px);
  margin: 10px 15px;
  }

}


@media only screen and (max-width: 479px) {

.pinned-section .pin-spacer {
  width:100%!important;
}

.pinned-element {
  float:none;
  width:100%;
  margin:0;
}

.pinned-element.left {
  padding-right:0px;
  margin-bottom:40px;
}

.pinned-element.right {
  padding-left:0px;
}

.scrolling-element {
  position:relative;
  float:none;
  width:100%;
  margin:0;
}

.scrolling-element.left {
  padding-right:0px;
  margin-bottom:40px;
}

.scrolling-element.right {
  padding-left:0px;
}

.content-slider.looped-carousel .clapat-slide {
  padding:0 20px;
}

.content-slider.small-looped-carousel .clapat-slide {
  padding:0 20px;
}

.disable-cursor .full .looped-carousel .clapat-controls, .disable-cursor .full_elementor .looped-carousel .clapat-controls {
  width: calc(100% - 40px);
}

.disable-cursor .full .small-looped-carousel .clapat-controls, .disable-cursor .full_elementor .small-looped-carousel .clapat-controls {
  width: calc(100% - 40px);
  max-width:560px;
}		
    
.clapat-button {
    margin-bottom: 20px;
}

.full > .team-members-list li, .full_elementor .team-members-list li {
  padding:30px 20px;
}

.team-member {
    font-size: calc(1rem + 14vw);
  line-height: calc(1rem + 13.5vw);
}

.team-members-list .hover-reveal {
  width: 100px;
  height: 133px;
}

.accordion.bigger-acc dt {
  margin-top: 20px;
  padding-top: 20px;
}

.accordion.bigger-acc dt:first-child {
  padding-top: 20px;
}

span.has-hover-image .hover-reveal {
  width: 150px;
  height: 94px;
}

span.has-hover-image.vertical .hover-reveal {
  width: 100px;
  height: 133px;
}

.flex-list {
  padding: 20px;
}

.moving-gallery li {
  width: 60vw;
}

.panels-container > .panel:first-child {
  margin-left:20px;
}

.panels-container > .panel:last-child {
  margin-right:20px;
}

.zoom-wrapper-gallery {
  padding: 0 10px;
}

.list-rotator li {
  font-size: calc(1rem + 14vw);
  line-height: calc(1rem + 13.5vw);
}

.start-thumbs-wrapper {
    padding: 0 10px;
  }

 .start-move-thumb, .start-move-thumb:nth-of-type(3n+2), .start-move-thumb:nth-of-type(3n+3) {
  width: calc(100% - 20px);
  height: calc(100vw - 20px);
  margin: 5px 10px;
  }
  
}